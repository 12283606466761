.search-result {

  padding-top: em(15px);

  &__item {
    margin-bottom: em(20px);
  }

  &__image {
    border: em(2px) solid $_gray-light;
    display: block;
    margin-bottom: em(10px, 15px);
    overflow: hidden;
    padding-top: 100%;
    position: relative;
    img {
      bottom: -100%;
      height: auto;
      left: -100%;
      margin: auto;
      max-height: 90%;
      max-width: 90%;
      position: absolute;
      right: -100%;
      top: -100%;
      width: auto;
    }
  }


  &__title {
    color: $_dark-blue-deep;
    display: block;
    font-size: em(18px);
    margin-bottom: em(18px, 18px);
    text-align: center;
    &:hover {
      color: $_marin;
    }
  }

  &__desc {
    margin-bottom: .5em;
  }

  &__label {
    background: $_pale;
    border-radius: em(2px);
    color: $_dark-pale;
    display: block;
    padding: em(15px);
    &::before {
      content: "\2192";
      color: $_marin;
      display: inline-block;
      margin-right: em(10px);
    }
  }

}