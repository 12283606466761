.model {

  margin-top: em(30px);

  &__item {
    border: em(2px) solid $_pale;
    margin-top: em(-2px);
    padding-top: 100%;
    position: relative;
    text-align: center;
    width: 100%;
  }
  &__inner {
    bottom: 0;
    left: 0;
    padding: em(20px);
    position: absolute;
    right: 0;
    top: 0;
    white-space: nowrap;
    &::after {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      width: 0;
    }
  }

  &__content {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    width: 100%;
  }

  &__title {
    color: $_marin;
    display: block;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  &__image {
    display: block;
    margin: 2em auto 0;
    max-height: 16em;
    max-width: 90%;
  }


}