.ticket {

  margin-bottom: 1.5em;

  a:not([class]) {
    color: $_marin;
  }

  &__item {
    margin-bottom: 1em;
  }

  &__info {
    margin-top: 1em;
  }

  &__row {
    border-top: em(2px, 14px) solid $_pale;
    font-size: em(14px);
    padding: em(7px, 14px) 0 em(9px, 14px);

    &_pre {
      white-space: pre;
    }
  }

  &__header {
    color: $_dark-pale;
    margin-bottom: em(5px, 14px);
  }
  &__value {
    color: $_dark-blue-deep;
  }

  &__button.button {
    padding-left: 1em;
  }

  &__button:hover &__gear::before {
    opacity: 0;
  }

  &__button:hover &__gear::after {
    opacity: 1;
  }


  &__gear {

    display: inline-block;
    height: em(31px);
    margin: em(-15px) 0 em(-10px);
    position: relative;
    width: em(31px);
    &::before, &::after {
      background: url(/i/design/ticket-gear.svg);
      background-size: 100%;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      opacity: 1;
      position: absolute;
      top: 0;
      transition: opacity .4s;
      width: 100%;
    }
    &::after {
      background: url(/i/design/ticket-gear-hover.svg);
      opacity: 0;
    }
  }


}