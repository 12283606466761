$indent: 15px;
$font-size: $indent;
$media: 320px;

@import "variables";
@import "tools";



body {
  font-family: $arimo;
  font-size: $font-size;
  font-size: ($font-size*100/$media)*1vw;
  line-height: 1.33;
}

@import "W:/Projects/Web/lnc/scss/320/__button.scss";
@import "W:/Projects/Web/lnc/scss/320/_banner.scss";
@import "W:/Projects/Web/lnc/scss/320/_brand.scss";
@import "W:/Projects/Web/lnc/scss/320/_breadcrumbs.scss";
@import "W:/Projects/Web/lnc/scss/320/_burger.scss";
@import "W:/Projects/Web/lnc/scss/320/_callback.scss";
@import "W:/Projects/Web/lnc/scss/320/_catalog.scss";
@import "W:/Projects/Web/lnc/scss/320/_char.scss";
@import "W:/Projects/Web/lnc/scss/320/_common.scss";
@import "W:/Projects/Web/lnc/scss/320/_company.scss";
@import "W:/Projects/Web/lnc/scss/320/_config-form.scss";
@import "W:/Projects/Web/lnc/scss/320/_configurator.scss";
@import "W:/Projects/Web/lnc/scss/320/_feature.scss";
@import "W:/Projects/Web/lnc/scss/320/_filter.scss";
@import "W:/Projects/Web/lnc/scss/320/_footer.scss";
@import "W:/Projects/Web/lnc/scss/320/_gallery-popup.scss";
@import "W:/Projects/Web/lnc/scss/320/_gallery.scss";
@import "W:/Projects/Web/lnc/scss/320/_license.scss";
@import "W:/Projects/Web/lnc/scss/320/_list-manager.scss";
@import "W:/Projects/Web/lnc/scss/320/_map.scss";
@import "W:/Projects/Web/lnc/scss/320/_menu.scss";
@import "W:/Projects/Web/lnc/scss/320/_model.scss";
@import "W:/Projects/Web/lnc/scss/320/_news.scss";
@import "W:/Projects/Web/lnc/scss/320/_page-width.scss";
@import "W:/Projects/Web/lnc/scss/320/_pagination.scss";
@import "W:/Projects/Web/lnc/scss/320/_popup-form.scss";
@import "W:/Projects/Web/lnc/scss/320/_popup.scss";
@import "W:/Projects/Web/lnc/scss/320/_product-menu.scss";
@import "W:/Projects/Web/lnc/scss/320/_project.scss";
@import "W:/Projects/Web/lnc/scss/320/_promo.scss";
@import "W:/Projects/Web/lnc/scss/320/_result.scss";
@import "W:/Projects/Web/lnc/scss/320/_review.scss";
@import "W:/Projects/Web/lnc/scss/320/_reviews.scss";
@import "W:/Projects/Web/lnc/scss/320/_scrollable.scss";
@import "W:/Projects/Web/lnc/scss/320/_search-result.scss";
@import "W:/Projects/Web/lnc/scss/320/_search.scss";
@import "W:/Projects/Web/lnc/scss/320/_service-inner.scss";
@import "W:/Projects/Web/lnc/scss/320/_service.scss";
@import "W:/Projects/Web/lnc/scss/320/_solution.scss";
@import "W:/Projects/Web/lnc/scss/320/_stock-thin.scss";
@import "W:/Projects/Web/lnc/scss/320/_stock.scss";
@import "W:/Projects/Web/lnc/scss/320/_ticket.scss";
@import "W:/Projects/Web/lnc/scss/320/_wysiwyg.scss";
