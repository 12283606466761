.company {

  &__header &__list-link {
    display: none;
  }

  &__item {
    display: inline-block;
    margin: 1%;
    vertical-align: middle;
    width: 43%;
  }

  &__holder {
    display: none;
  }

  &__image img {
    height: auto;
    max-width: 100%;
    width: auto;
  }

  &__list-link {
    border-bottom: em(1px) solid $_marin;
    color: $_marin;
    display: inline-block;
    font-family: $openSans;
    font-size: em(13px);
    font-weight: bold;
    line-height: 1.8em;
    margin-top: 2em;
    text-decoration: none;
    text-transform: uppercase;
    transition: border-color .2s, color .2s;
    will-change: border-color, color;
    &:hover {
      border-color: $_dark-blue;
      color: $_dark-blue;
    }
  }

}