.reviews {

  color: $_dark-blue-deep;

  &__year {
    color: $_marin;
    font-family: $openSans;
    font-size: em(40px);
    font-weight: lighter;
    margin-bottom: em(20px, 40px);
  }

  &__item {
    border: em(2px) solid $_pale;
    margin-bottom: em(28px);
    padding: em(25px) em(18px);
  }

  &__image {
    display: block;
    margin-bottom: em(15px);
    max-width: 100%;
  }

  &__text {
    margin-bottom: em(18px);
  }

  &__signature {
    color: $_dark-pale;
    font-style: italic;
  }

}