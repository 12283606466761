.configurator {
  color: white;
  position: relative;

  &__item {
    margin-bottom: em(40px);
    position: relative;
    text-align: center;
    z-index: 1;
  }

  &__image {
    backface-visibility: hidden;
    display: block;
    margin: 0 auto em(10px);
    width: 70%;
  }

  &__title {
    font-size: em(26px);
    font-weight: lighter;

    a {
      border-bottom: em(1px) solid $_ultra-marin;
      text-decoration: none;
    }
  }

  &__list {
    background: #037cb6; /* Old browsers */
    background: linear-gradient(to bottom, #037cb6 0%,#0eadb3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#037cb6', endColorstr='#0eadb3',GradientType=0 ); /* IE6-9 */
    left: em(-$indent);
    min-height: 100%;
    opacity: 0;
    padding: em(20px);
    position: absolute;
    right: em(-$indent);
    text-align: left;
    transition: opacity .4s;
    top: em(-$indent*3);
    z-index: -1;
  }

  &__item:last-child &__list {
    bottom: em(-80px);
    top: auto;
  }

  &__item:hover {
    z-index: 10;
  }
  &__item:hover &__list {
    opacity: 1;
    transition: opacity .4s, z-index 0s linear .1s;
    z-index: 10;
  }

  &__list-title {
    font-size: em(28px);
    margin-bottom: 1em;
  }

  &__link {
    display: block;
    font-size: em(16px);
    padding: em(2px) 0;
    text-decoration: underline;
    transition: color 200ms;
    &:hover {
      color: $_marin-light;
    }
    &_list {
      margin-top: em(10px);
    }
  }

}