.config-form {

  color: $_dark-blue-deep;
  margin-bottom: 2em;
  position: relative;

  &__row {
    margin: em(25px) 0;
    transition: background-color 1s;
    &.aos-animate {
      transform: none!important;
    }
  }

  &__header {
    background: $_pale;
    color: $_dark-blue;
    font-family: $openSans;
    font-size: em(15px);
    font-weight: bold;
    margin-bottom: em(25px);
    padding: em(20px) 0 em(20px) em(100px);
    position: relative;
    text-transform: uppercase;
    &::after {
      border: em(8px) solid transparent;
      border-top: em(8px) solid $_pale;
      content: "";
      display: block;
      height: 0;
      left: em(20px);
      margin-top: -1px;
      position: absolute;
      top: 100%;
      width: 0;
    }
    &_string {
      padding: 0;
    }
    &_stepless {
      padding-left: em(13px);
    }
  }

  &__title {
    display: inline-block;
    font-family: $openSans;
    font-size: em(16px);
    font-weight: bold;
    margin: 0 0 .5em;
    text-transform: uppercase;
  }


  &__item {
    display: block;
    margin-bottom: 0;
    padding-left: em(13px);
    text-align: justify;
    &::after {
      content: "";
      display: inline-block;
      width: 100%;
    }
    & > * {
      text-align: left;
    }
    &_left {
      text-align: left;
    }
    &_nowrap {
      white-space: nowrap;
    }
  }

  &__wrapper_indent {
    padding-left: em(13px);
    .scrollable--parent {
      margin-left: em(-2*$indent);
    }
  }


  &__item .config-form__label + .config-form__select {
    display: inline-block;
  }

  &__number {
    border: em(2px) solid $_dark-blue;
    border-radius: em(10px);
    display: inline-block;
    height: em(32px);
    left: em(13px);
    line-height: em(29px);
    margin-right: .2em;
    margin-top: em(-16px);
    padding: 0 .5em;
    position: absolute;
    text-align: center;
    top: 50%;
    width: em(80px);
  }

  &__label {
    display: inline-block;
    margin: .5em .5em .5em 0;
  }

  &__brands {
    @include column-view(268px, 15px, 2, true);
  }

  &__brand {
    margin-bottom: em(15px);
    padding-top: em(80px);
    position: relative;
    & input[type=checkbox] {
      left: 0;
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 10;
    }
    & span {
      background: white;
      border: em(4px) solid white;
      border-radius: em(4px);
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: border-color .2s;
      width: 100%;
    }

    & input:checked + span {
      border-color: $_ultra-marin;
    }

    &:hover span, &:hover input:checked + span {
      border-color: $_marin-light;
    }

    & img {
      bottom: -200%;
      display: block;
      height: auto;
      left: -200%;
      margin: auto;
      max-height: 85%;
      max-width: 85%;
      position: absolute;
      right: -200%;
      top: -200%;
      width: auto;
    }
  }


  &__input {
    background: white;
    border: em(2px) solid $_gray-middle;
    border-radius: em(2px);
    color: $_dark-blue-deep;
    display: block;
    width: 100%;
    padding: em(12px) em(30px) em(12px) em(14px);
    position: relative;
  }

  &__select {
    background: white;
    border: em(2px) solid $_gray-middle;
    border-radius: em(2px);
    cursor: pointer;
    min-width: 30%;
    position: relative;


    &__item a, &__title {
      color: $_dark-blue-deep;
      cursor: pointer;
      display: block;
      font-weight: normal;
      padding: em(12px) em(45px) em(12px) em(14px);
      text-decoration: none;
      text-transform: none;
    }

    &__item:hover {
      background: $_gray-light;
      cursor: pointer;
    }

    &__list {
      background: white;
      border: em(2px) solid $_gray-middle;
      border-top: none;
      display: none;
      max-height: em(300px);
      left: em(-2px);
      margin-top: em(-2px);
      overflow: auto;
      position: absolute;
      right: em(-2px);
      top: 100%;
      white-space: normal;
      z-index: 10;
    }

    &__button {
      @include arrow-down($_dark-blue, em(10px), em(3px));
      display: block;
      margin-top: em(-7px);
      position: absolute;
      right: em(18px);
      top: 50%;
    }

    &.open {
      z-index: 2;
    }

    &.open &__list {
      display: block;
    }
  }



  &__checkbox {
    display: inline-block;
    margin-bottom: em(20px);
    margin-right: 1em;
    min-width: 20%;
    position: relative;
    & input {
      bottom: 0;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
    & span {
      display: block;
      position: relative;
      &::before {
        background: white;
        border: em(2px) solid $_gray-middle;
        content: "";
        display: inline-block;
        height: em(20px);
        margin-right: .2em;
        vertical-align: em(-4px);
        width: em(20px);
      }
      &::after {
        border-bottom: em(2px) solid $_marin;
        border-left: em(2px) solid $_marin;
        //content: "";
        display: block;
        height: em(5px);
        left: em(5px);
        position: absolute;
        top: em(7px);
        transform: rotate(-45deg);
        width: em(10px);
      }
    }
    & input:checked + span {
      &::after {
        content: "";
      }
    }

  }

  &__add-link {
    color: $_dark-blue;
    display: inline-block;
    padding-left: em(40px);
    position: relative;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  &__plus {
    display: block;
    left: em(13px);
    height: em(18px);
    position: absolute;
    top: 0;
    width: em(18px);
    @include plus($_ultra-marin-darker ,100%, 20%);
  }


  &__remove-link {
    border: em(2px) solid $_marin;
    border-radius: 50%;
    display: block;
    float: right;
    height: em(30px);
    width: em(30px);
    &::before {
      background: $_marin;
      content: "";
      display: block;
      height: em(2px);
      margin: auto;
      position: relative;
      top: em(12px);
      width: em(15px);
    }
  }



  &__row_main {
    background: $_marin;
    border-radius: em(4px);
    color: white;
    margin: 0;
    padding: em(24px) em(13px) 0;
  }

  &__row_selected {
    background: $_dark-blue;
  }

  &__row_main &__item {
    color: white;
    padding: 0;
  }

  &__row_main &__header {
    color: white;
    background: none;
    margin-left: em(-13px);
    padding: 0 0 0 em(100px);
    &::after {
      content: none;
    }
  }

  &__row_main &__number {
    border-color: white;
  }



  &__row_main &__select, &__row_main &__select__list {
    border-color: white;
  }

  &__row_main &__checkbox span::before {
    border-color: $_marin-dark;
  }


  &::before {
    background: rgba(white, .7);
    content: "";
    height: 100%;
    left: -99999px;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity .2s, left 0s linear .2s;
    width: 100%;
    z-index: 9;
  }


  &__loader,
  &__loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  &__loader {
    //margin: em(60px) auto;
    font-size: 10px;
    text-indent: -9999em;
    border-top: 1.1em solid rgba($_ultra-marin-dark, 0.2);
    border-right: 1.1em solid rgba($_ultra-marin-dark, 0.2);
    border-bottom: 1.1em solid rgba($_ultra-marin-dark, 0.2);
    border-left: 1.1em solid $_ultra-marin-dark;
    animation: load8 1.1s infinite linear;
    left: -9999px;
    margin-left: -5em;
    margin-top: -5em;
    opacity: 0;
    position: fixed;
    transition: opacity .2s, left 0s linear .2s;
    top: 40%;
    z-index: 10;
  }

  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  &_loading &__loader {
    left: 50%;
    opacity: 1;
    transition: opacity .2s;
  }

  &_loading::before {
    left: 0;
    opacity: 1;
    transition: opacity .2s;
  }

  &__attention {
    color: $_marin;
    display: block;
    font-size: em(13px);
    margin: .5em 0;
    text-align: right;
    width: 100%;
  }

  &__table {
    border-bottom: em(2px) solid $_marin;
    border-spacing: em(2px) 0;
    th, td  {
      border-bottom: em(2px) solid white;
      padding: em(17px) em(10px);
    }
    th {
      background: $_marin;
      color: white;
      font-weight: normal;
      text-align: left;
    }
    td {
      vertical-align: top;
      &.config-form__button-holder {
        text-align: center;
        width: 1px;
      }
    }
    tr:nth-child(even) td {
      background: $_pale;
    }
    tr:last-child td {
      border-bottom: 0;
    }
  }

  &__group-header {
    color: $_marin;
    font-family: $openSans;
    font-size: em(20px);
    font-weight: normal;
    line-height: 1.2em;
    margin: em(30px, 20px) 0 em(22px, 20px);
  }

}