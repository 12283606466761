.review {
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  word-spacing: -.3em;

  h1 {
    white-space: normal;
    word-spacing: 0;
  }

  &__item {
    display: inline-block;
    padding: 0 em($indent);
    vertical-align: top;
    width: 100%;
  }

  &__company {
    margin: 0 auto em($indent);
    white-space: normal;
    width: 90%;
  }

  &__logo, &__person {
    display: inline-block;
    padding: 0 .25em;
    vertical-align: middle;
  }

  &__logo {
    margin-bottom: .5em;
    text-align: center;
    img {
      height: auto;
      max-height: 100%;
      max-width: 100%;
      width: auto;
    }
  }

  &__person {
    text-align: left;
  }

  &__name {
    color: $_marin;
    font-style: italic;
    margin-bottom: .25em;
  }

  &__position {
    color: $_dark-blue;
    font-size: em(13px);
    font-style: italic;
  }

  &__content {
    text-align: left;
    white-space: normal;
  }

  &__text {
    color: $_dark-blue-deep;
    margin-bottom: 1em;
  }

  &__button {
    font-size: em(12px);
  }

  &__arrow {
    border: em(2px) solid $_marin;
    border-radius: 50%;
    height: em(40px);
    position: absolute;
    text-align: center;
    top: em(120px);
    transition: border-color .2s;
    will-change: border-color;
    width: em(40px);
    &::after {
      content: "";
      margin-top: em(12px);
      transition: border-color .2s;
      will-change: border-color;
    }
    &_left {
      left: em(-$indent);
      &::after {
        @include arrow-left($_marin, em(12px), em(2px));
        margin-left: em(10px);
      }
    }
    &_right {
      right: em(-$indent);
      &::after {
        @include arrow-right($_marin, em(12px), em(2px));
        margin-right: em(10px);
      }
    }
    &:hover, &:hover::after {
      border-color: $_dark-blue;
    }

  }



}