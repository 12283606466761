.gt-mobile, .gt-tablet {
  display: none;
}
.lt-tablet, .lt-desktop {
  display: block;
}
span.lt-tablet, span.lt-desktop,
br.lt-tablet, br.lt-desktop
{
  display: inline;
}

span.e-tablet, br.e-tablet, .e-tablet {
  display: none;
}