.callback {

  color: white;

  & > span {
    display: block;
    text-align: left;
  }

  &__desc, &__phone, &__email {
    font-family: $openSans;
    font-size: em(26px);
    line-height: 1.2em;
    font-weight: lighter;
    margin-bottom: em(15px, 26px);
  }

  &__phone {
    display: inline-block;
    font-size: em(32px);
    margin-bottom: em(10px, 32px);
    white-space: nowrap;
  }

  &__email {
    display: inline-block;
    margin-bottom: em(48px, 26px);
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  input[type=text] {
    @extend .button;
    background: white;
    color: $_dark;
    font-weight: normal;
    margin-bottom: 1.5em;
    padding: 1em;
    text-align: left;
    text-transform: none;
    width: 100%;
  }

  img {
    cursor: pointer;
    max-width: 100%;
  }

  &__submit {
    cursor: pointer;
    margin: auto;
  }

  .error {
    color: red;
    margin-bottom: .4em;
    margin-top: -1.7em;
  }

  &__agreement  {
    display: inline-block;
    margin-bottom: 1.5em;
    position: relative;
    text-align: left;

    .error {
      margin-top: 0;
      text-align: center;
    }
    input {
      opacity: 0;
      height: 100%;
      position: absolute;
      width: 100%;
    }
    span {
      cursor: text;
      display: block;
      position: relative;
      z-index: 2;
      &::before {
        background: $_gray-light;
        border: em(2px) solid $_gray;
        border-radius: em(3px);
        content: "";
        cursor: pointer;
        display: inline-block;
        height: em(18px);
        margin-right: em(8px);
        vertical-align: middle;
        width: em(18px);
      }
      &::after {
        border-bottom: em(2px) solid $_marin;
        border-left: em(2px) solid $_marin;
        cursor: pointer;
        display: block;
        height: em(6px);
        margin-top: em(-3px);
        position: absolute;
        left: em(4px);
        top: em(9px);
        transform: rotate(-45deg);
        width: em(10px);
        z-index: 2;
      }
    }
    input:checked + span {
      &::before {
        background: white;
        border-color: $_marin;
      }
      &::after {
        content: "";
      }
    }
  }

}