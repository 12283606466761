.license {

  &__item {

    margin-bottom: em(45px);
    padding-top: em(5px);
    text-align: center;
  }

  &__image {
    border: em(2px) solid $_gray-middle;
    cursor: pointer;
    display: block;
    margin: 0 auto em(27px);
    max-width: 75%;
  }

  &__title {
    color: $_marin;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

}