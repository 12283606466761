.service-inner {

  padding-top: em(2px);

  &__item {
    border: em(2px) solid $_pale;
    padding: em(20px) em(13px) em(27px);
    margin-top: em(-2px);
  }

  &__image {
    display: block;
    margin: 0 auto 2em;
    width: 90%;

  }

  &__title {
    color: $_marin;
    display: inline-block;
    font-family: $openSans;
    font-size: em(18px);
    margin-bottom: .2em;
    width: 100%;
  }

  &__link {
    color: $_marin;
    display: block;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  &__detail {
    background: $_pale;
    border-radius: em(4px);
    color: $_dark-pale;
    display: inline-block;
    font-family: $openSans;
    font-size: em(12px);
    font-weight: bold;
    margin-bottom: 1em;
    padding: em(10px) em(15px);
    text-transform: uppercase;
  }

  &__text {
    margin-bottom: 1em;
  }

}