.map {

  color: $_dark-blue-deep;

  &__content {
    height: em(535px);
  }

  &__desc {
    margin-bottom: 1em;
  }

  &__header {
    font-family: $openSans;
    font-size: em(16px);
    font-weight: bold;
    position: relative;
    text-transform: uppercase;
  }

  &__desc_car &__header {
    min-height: em(50px);
    padding: .7em 0 0 em(110px);
    &::before {
      background: url(/i/design/map-car.svg) no-repeat;
      content: "";
      display: block;
      height: em(43px);
      left: 0;
      margin-top: em(-21px);
      position: absolute;
      top: 50%;
      width: em(95px);
    }
  }

  &__desc_traction &__header {
    min-height: em(50px);
    padding: .7em 0 0 em(110px);
    &::before {
      background: url(/i/design/map-traction.svg) no-repeat;
      content: "";
      display: block;
      height: em(38px);
      left: 0;
      margin-top: em(-10px);
      position: absolute;
      top: 50%;
      width: em(90px);
    }
  }

  &__text {
    margin: 1em 0;
  }



}