.gallery {

  position: relative;

  &__view {
    border: em(2px) solid $_pale;
    padding-top: 100%;
    position: relative;

    & > img {
      bottom: -100%;
      height: auto;
      left: -100%;
      margin: auto;
      max-height: 100%;
      max-width: 100%;
      opacity: 0;
      position: absolute;
      right: -100%;
      top: -100%;
      transform: translateZ(0);
      transition: visibility 0s, opacity 0.5s linear;
      visibility: hidden;
      width: auto;
      &.active {
        opacity: 1;
        visibility: visible;
      }
    }
  }


  &__thumb {
    border: em(2px) solid $_pale;
    display: inline-block;
    margin: 0 em(4px);
    padding-top: em(100px);
    position: relative;
    width: em(100px);
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    & > img {
      bottom: -100%;
      display: block;
      height: auto;
      left: -100%;
      margin: auto;
      max-height: 99%;
      max-width: 99%;
      position: absolute;
      right: -100%;
      top: -100%;
      width: auto;
    }
    &.active {
      border-color: $_marin;
    }
  }

  &__scroll {

    text-align: center;
    user-select: none;
    white-space: nowrap;
    word-spacing: -.3em;
  }

  &__loupe {
    cursor: pointer;
    @include loupe($_ultra-marin, em(22px));
    height: em(30px);
    position: absolute;
    right: em(13px);
    top: em(13px);
    width: em(30px);
    z-index: 10;
  }

  &__plus {
    display: block;
    height: em(10px);
    left: 50%;
    margin-left: em(-5px);
    position: absolute;
    top: em(6px);
    width: em(10px);
    @include plus($_ultra-marin, em(10px), em(2px));
  }

}