.news {
  margin-bottom: em(65px);
  &__item {
    margin-bottom: em(30px);
  }

  &__date {
    background: $_pale;
    border-radius: em(4px);
    color: $_dark-pale;
    display: inline-block;
    font-size: em(13px);
    margin-bottom: 1em;
    padding: .6em 1em;
  }

  &__title {
    color: $_dark-blue-deep;
    display: block;
    font-size: em(17px);
    line-height: 1.3em;
    margin-bottom: .5em;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  &__desc {
    color: $_dark-pale;
    font-size: em(13px);
    padding-left: em(20px);
    position: relative;
    text-align: justify;
    &::before {
      color: $_marin;
      content: "\2192";
      left: 0;
      position: absolute;

    }
  }

}