.service {

  &__item {
    border: em(2px) solid $_gray-light;
    margin-top: em(-2px);
    padding: em(13px);
    &:first-child {
      margin-top: 0;
    }
  }

  &__image {
    display: block;
    height: auto;
    margin: em(24px) auto;
    width: 55%;
  }

  &__content {
    &::after {
      clear: both;
      content: "";
      display: block;
    }
  }

  &__header {
    color: $_marin;
    font-family: $openSans;
    font-size: em(22px);
    line-height: 1.3em;
    margin-bottom: .8em;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__list {
    margin-bottom: 1em;
    &_hidden {
      display: none;
    }
  }

  &__link {
    color: $_dark-blue-deep;
    display: block;
    font-size: em(14px);
    line-height: 1.2em;
    margin: .5em 0;
    padding-left: em(26px);
    position: relative;
    &::before {
      color: $_marin;
      content: "\2192";
      left: 0;
      position: absolute;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  &__detail {
    display: block;
    float: right;
    font-size: em(13px);
    padding: em(10px) em(15px);
  }

}