.popup-form {

  background: white;
  color: $_dark_blue;
  display: inline-block;
  font-size: em(16px);
  line-height: 1.25em;

  padding: 2em 1em 1em;
  position: relative;
  text-align: justify;
  vertical-align: middle;
  white-space: normal;

  &::after {
    content: "";
    display: inline-block;
    width: 100%;
  }

  &--wrapper {
    bottom: 0;
    left: 0;
    overflow: scroll;
    padding: 1em;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    white-space: nowrap;

    &::after {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
  }

  &__title {
    color: $_marin;
    font-family: $openSans;
    font-size: em(18px, 16px);
    font-weight: bold;
    margin: 1em 1em 1em 0;
    text-transform: uppercase;
  }


  &__row {
    display: inline-block;
    margin-bottom: 1em;
    text-align: left;
    vertical-align: top;
    white-space: normal;
    width: 100%;
    &_captcha img {
      display: block;
      margin: 0 auto 1em;
    }
    &_checkbox {
      position: relative;
      input {
        opacity: 0;
        position: absolute;
      }
      span {
        cursor: text;
        position: relative;
        z-index: 2;
        &::before {
          background: $_gray-light;
          border: em(2px) solid $_gray;
          border-radius: em(3px);
          content: "";
          cursor: pointer;
          display: inline-block;
          height: em(18px);
          margin-right: em(8px);
          vertical-align: middle;
          width: em(18px);
        }
        &::after {
          border-bottom: em(2px) solid $_marin;
          border-left: em(2px) solid $_marin;
          cursor: pointer;
          display: block;
          height: em(6px);
          margin-top: em(-3px);
          position: absolute;
          left: em(4px);
          top: em(9px);
          transform: rotate(-45deg);
          width: em(10px);
          z-index: 2;
        }
      }
      input:checked + span {
        &::before {
          background: white;
          border-color: $_marin;
        }
        &::after {
          content: "";
        }
      }
    }
  }

  &__name {
    display: block;
    margin-bottom: .3em;
  }

  input, textarea {
    border: em(2px) solid $_gray-middle;
    border-radius: em(2px);
    display: block;
    padding: em(10px);
    transition: border-color .2s;
    width: 100%;
    &:focus {
      border-color: $_marin;
    }
    &.popup-form__error {
      border-color: $_red;
    }
  }

  textarea {
    height: 10em;
    resize: none;
  }

  .button {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  &__closer {
    display: block;
    height: em(20px);
    position: absolute;
    right: 1em;
    top: 1em;
    width: em(20px);
    @include cross($_dark-blue);
    &::before, &::after {
      transition: background-color .2s;
    }
    &:hover::before, &:hover::after {
      background: $_marin;
    }
  }

  .error {
    color: red;
  }



}