.catalog {

  &__item {
    display: block;
    margin-bottom: 3em;
  }

  &__image {
    display: block;
    height: auto;
    margin: 0 auto 1em;
    max-width: 100%;
    width: auto;
  }

  &__title {
    padding-left: 1em;
    padding-right: 1em;
    transition: background .2s, color .2s;

  }

  &__item:hover &__title{
    background: $_ultra-marin;
    color: $_dark;
  }

}