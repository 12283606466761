.scrollable {
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 20px;
  position: relative;
  top: 20px;

  &--parent {

    margin: -20px em(-$indent) 0;
    overflow: hidden;
    position: relative;


    &_fade-right::after {
      background: linear-gradient(to right, transparent 0%,#ffffff 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='transparent', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
      content: "";
      bottom: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 20%;
    }
  }

}