.feature {
  &__text {
    color: $_dark-blue-deep;
    p {
      margin: .7em 0;
    }
    a {
      color: $_marin;
    }
  }

  &__menu {

    cursor: pointer;
    height: em(13px*2+13px*1.4);
    margin: 2em 0 0;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: em(13px);
      top: em(23px);
      margin-top: em(-8px);
      z-index: 10;
      @include arrow-down($_marin);
    }
  }

  &__wrapper {
    background: white;
    border: em(2px) solid $_gray-middle;
    left: 0;
    position: relative;
    top:0;
    z-index: 10;
  }

  &__tab {
    color: $_marin;
    display: none;
    font-size: em(13px);
    font-weight: bold;
    padding: 1em;
    text-transform: uppercase;
    &.active {
      display: block;
    }
    &_container {
      display: none;
    }
  }

  &__menu_open &__tab {
    display: block;
    &:hover, &.active {
      background: $_gray-light;
    }
  }

  &__menu_open &__tab_container {
    display: none;
  }

  &__content {
    position: relative;
  }

  &__content &__item {
    left: -9999999px;
    opacity: 0;
    position: absolute;
    top: -9999999px;
    transition: opacity .4s;
  }

  &__content &__item.visible {
    left: auto;
    opacity: 1;
    position: relative;
    top: auto;
  }


}