.char {

  border-collapse: collapse;
  margin: 0 em($indent);
  min-width: 150%;



  &__family, &__data {
    border-bottom: em(2px) solid $_pale;
    padding: 1em 0 1em 1em;
    vertical-align: top;
  }

  &__data_middle {
    vertical-align: middle;
  }

  &__data_outer {
    padding-right: 3em;
  }

  &__family {
    padding: 1.5em .5em 1.5em 0;
    &_middle {
      padding: 1em .5em 1em 0;
    }
    img {
      width: 100%;
    }
  }

  &__table {
    border-collapse: collapse;
    table-layout: fixed;
    min-width: 100%;

    td {
      border: none;
    }

    tr:nth-child(odd) td {
      background: $_pale;
    }

  }

  &__property, &__value {
    padding: .5em 1em;
    vertical-align: top;
    width: 45%;
  }

  &__value {
    width: 55%;
    white-space: pre-wrap;
  }




}