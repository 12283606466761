.product-menu {

  font-size: em(13px);

  &__head {
    color: $_gray;
    font-weight: bold;
    &::after {
      border-bottom: em(1px) solid $_gray;
      content: "";
      display: block;
      margin: 1.5em .5em 0;
    }
  }

  &__header {
    display: inline-block;
    margin: 0 .5em .5em;
  }

  &__body {
    .wrapper > & {
      margin-bottom: em(-30px);
    }
    padding: 2em .5em 0;
  }

  &__list {
    margin-bottom: em(30px);
    color: $_gray;
  }
  &__item {
    padding: em(5px) 0;
  }

  &__title {
    color: white;
    margin-bottom: em(18px);
    text-transform: uppercase;
  }

  &__link:hover {
    text-decoration: underline;
  }

}