.solution {

  &__header {
    color: $_marin;
    font-family: $openSans;
    font-size: em(25px);
    line-height: 1.4em;
    text-transform: uppercase;
    &_service {
      font-size: em(20px);
      text-transform: none;
      &:hover {
        text-decoration: underline;
      }
    }
    & a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__list {
    color: $_dark-blue-deep;
    font-size:em(15px, 25px);
    line-height: normal;
    text-transform: none;
    margin: 1.33em 0 2.5em;
    li {
      margin: em(5px) 0;
      padding-left: em(25px);
      position: relative;
      &::before {
        background: $_ultra-marin;
        content: "";
        display: block;
        height: em(2px);
        left: 0;
        position: absolute;
        top: .6em;
        width: em(12px);
      }
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline
        }
      }
    }
  }

  &__item {
    text-align: center;
    margin-bottom: 2em;
  }

  &__image {
    display: block;
    margin: 1em auto;
    width: 50%;
  }


}