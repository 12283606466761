.brand {

  font-size: em(14px);
  padding: 0 em(10px);

  &__item {
    margin-bottom: em(28px);
  }

  &__link {
    color: $_blue;
    display: block;
    line-height: 1.2em;
    margin: .5em 0;
    position: relative;
    padding-left: em(16px);

    &::before {
      background: $_ultra-marin-dark;
      content: "";
      height: em(2px);
      left: 0;
      display: block;
      position: absolute;
      top: .5em;
      width: em(10px);
    }
    &_brand {
      margin-bottom: em(8px);
      padding: 0;
      &::before {
        content: none;
      }
      &:hover {
        text-decoration: none;
      }
    }
    &_all {
      color: $_marin;
      margin: .75em 0;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  &__image {
    display: block;
    height: auto;
    max-width: em(194px);
    width: auto;
  }

}