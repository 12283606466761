.filter {
  background: $_pale;
  margin: em(60px) em(-$indent) 0;
  padding: em($indent) 0 0 em(10px);
  position: relative;

  &__wrapper {

    display: flex;
    //flex-flow: row wrap;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: stretch;

    margin-top: -2.333em;
    padding-top: em(24px);
    text-align: justify;
    transition: opacity .6s;
    word-spacing: -.3em;

    &::after {
      content: "";
      display: inline-block;
      height: 1.333em;
      width: 100%;
    }
  }

  &__closer, &__opener {
    background: $_pale;
    color: $_marin;
    display: block;
    font-size: em(13px);
    height: em(42px, 13px);
    line-height: 1em;
    padding: em($indent, 13px);
    position: absolute;
    right: 0;
    top: em(-42px, 13px);
  }

  &__opener {
    display: none;
  }

  &__cross {
    @include cross($_marin);
    display: inline-block;
    height: em(10px, 13px);
    position: relative;
    width: em(10px, 13px);
  }
  &__plus {
    @include plus($_marin, em(10px, 13px), em(3px, 13px));
    display: inline-block;
    height: em(10px, 13px);
    position: relative;
    width: em(10px, 13px);
  }

  &__item {
    display: inline-block;
    flex: auto;
    padding: em(10px) em(10px) 0 0;
    position: relative;

    transition: opacity .6s;
    white-space: nowrap;
    word-spacing: 0;

    input[type=checkbox] {
      bottom: 0;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    input[type=text] {
      border:  em(2px) solid $_gray;
      border-radius: em(4px);
      color: $_dark;
      padding: .5em 1em;
      font-size: em(18px);
      &:focus {
        border-color: $_marin;
      }
    }
  }

  &__text {
    display: block;
    position: relative;
    text-align: left;
    user-select: none;
    &::before {
      background: $_gray-light;
      border: em(2px) solid $_gray;
      border-radius: em(3px);
      content: "";
      display: inline-block;
      height: em(18px);
      margin-right: em(8px);
      vertical-align: middle;
      width: em(18px);
    }
    &::after {
      border-bottom: em(2px) solid $_marin;
      border-left: em(2px) solid $_marin;
      display: block;
      height: em(6px);
      margin-top: em(-3px);
      position: absolute;
      left: em(4px);
      top: 50%;
      transform: rotate(-45deg);
      width: em(10px);
      z-index: 2;
    }
  }

  &__brand {
    border: em(2px) solid $_gray-middle;
    border-radius: em(3px);
    color: $_dark-blue-deep;
    display: block;
    font-weight: bold;
    padding: .5em;
    text-align: center;
    transition: background-color .2s, border-color .2s, color .2s;
    user-select: none;
    &:hover {
      border-color: $_ultra-marin;
    }
  }

  &__item input:checked + &__text {
    &::before {
      background: white;
      border-color: $_marin;
    }
    &::after {
      content: "";
    }
  }

  &__item input:checked + &__brand {
    border-color: $_marin;
    color: $_marin;
  }


  &.closed {
    margin-bottom: em(80px);
    padding: 0;
  }

  &.closed &__wrapper {
    left: -99999px;
    opacity: 0;
    position: absolute;
  }

  &.closed &__opener {
    display: block;
  }
  &.closed &__closer {
    display: none;
  }


}