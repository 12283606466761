.gallery-popup {
  display: none;
  font-size: 0;
  line-height: 0;
  &.popup {
    display: block;
  }
  &__image {
    bottom: 5%;
    display: none;
    left: 0;
    margin: auto;
    max-height: 90%;
    max-width: 90%;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 5%;
    &.active {
      opacity: 1;
      display: block;
    }
  }

  &__closer {
    @include cross(white, 100%, 4px);
    height: 30px;
    position: absolute;
    right: 5%;
    top: 5%;
    width: 30px;

  }
}