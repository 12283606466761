.wysiwyg {
  color: $_dark-blue-deep;

  p:not([class]) {
    margin: em(10px) 0;
  }

  ul li {
    list-style: none;
    margin: em(10px) 0;
    padding-left: em(24px);
    position: relative;
    &::before {
      background: $_ultra-marin-dark;
      content: "";
      display: block;
      height: em(2px);
      left: 0;
      position: absolute;
      top: .6em;
      width: em(13px);
    }
  }

  .blue {
    color: $_marin;
  }

  h2 {
    font-family: $openSans;
    font-size: em(25px);
    line-height: 1.2em;
    font-weight: lighter;
    margin-bottom: em(17px, 25px);
  }

  h3 {
    font-family: $openSans;
    font-size: em(20px);
    font-weight: normal;
    line-height: 1.2em;
    margin-bottom: em(22px, 20px);
  }

  * + h2 {
    margin-top: em(30px, 26px);
  }

  * + h3 {
    margin-top: em(30px, 20px);
  }

  img {
    display: block;
    height: auto;
    margin-bottom: em(15px);
    max-width: 100%;
    width: 100%;
  }

  .button {
    background: $_pale;
    color: $_dark-pale;
    display: inline-block;
    padding: em(10px) em(20px);
    &:hover {
      background: $_dark-blue;
      color: white;
    }
  }

  ol {
    counter-reset: ol1;
  }

  ol ol {
    counter-reset: ol2;
  }

  ol ol ol {
    counter-reset: ol3;
  }

  ol > li {
    counter-increment: ol1;
    list-style: none;
    margin: em(10px) 0;
    padding-left: em(30px);
    position: relative;
    &::before {
      content: counter(ol1) ".";
      color: $_marin;
      font-weight: bold;
      left: 0;
      position: absolute;
    }
  }

  ol ol > li {
    counter-increment: ol2;
    padding-left: em(42px);
    &::before {
      content: counter(ol1) "." counter(ol2) ".";
    }
  }

  ol ol ol > li {
    counter-increment: ol3;
    padding-left: em(54px);
    &::before {
      content: counter(ol1) "." counter(ol2) "." counter(ol3) ".";
    }
  }

  a:not([class]) {
    color: $_marin;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  table {
    border-bottom: em(2px) solid $_marin;
    border-spacing: em(2px) 0;
    th, td  {
      border-bottom: em(2px) solid white;
      padding: em(17px) em(10px);
    }
    th {
      background: $_marin;
      color: white;
      font-weight: normal;
      text-align: left;
    }
    td {
      vertical-align: top;
    }
    tr:nth-child(even) td {
      background: $_pale;
    }
    tr:last-child td {
      border-bottom: 0;
    }
  }

  form:not([class]) {
    font-size: em(16px);
    img {
      display: block;
      max-width: 100%;
    }
    .error {
      color: red;
      font-size: em(14px, 16px);
    }
    label {
      display: block;
      margin-bottom: em(20px);
      & > span {
        display: block;
        margin: em(12px) 0;
      }
    }
    input, select, textarea {
      background: $_pale;
      border: em(2px) solid $_gray-middle;
      border-radius: em(2px);
      display: block;
      line-height: 1.4em;
      padding: em(10px);
      resize: none;
      transition: background-color .2s, border-color .2s;
      width: 100%;
      &:focus {
        background: white;
        border-color: $_marin;
      }

    }
    textarea {
      height: em(108px);
    }
    *[type="submit"] {
      border-radius: em(2px);
      display: block;
      font-family: $openSans;
      font-weight: bold;
      line-height: em(20px);
      margin-top: em(28px, 16px);
      padding: 1em 0;
      text-align: center;
      text-transform: uppercase;
      transition: background .4s, color .4s;
      width: 100%;
      will-change: background, color;
      background: $_marin;
      color: white;
      &:hover {
        background: $_ultra-marin;
        color: $_dark;
      }
    }
    .textarea {
      width: 100%;
    }
    .checkbox {
      font-size: em(13px);
      line-height: 1.2em;
      position: relative;
      width: 100%;
      input {
        opacity: 0;
        position: absolute;
      }
      &_independent {
        font-size: em(16px);
      }
      span {
        cursor: text;
        padding-left: 2em;
        position: relative;
        z-index: 2;
        &::before {
          background: $_gray-light;
          border: em(2px) solid $_gray;
          border-radius: em(3px);
          content: "";
          cursor: pointer;
          display: inline-block;
          height: em(18px);
          left: 0;
          margin-right: em(8px);
          position: absolute;
          vertical-align: middle;
          width: em(18px);
        }
        &::after {
          border-bottom: em(2px) solid $_marin;
          border-left: em(2px) solid $_marin;
          cursor: pointer;
          display: block;
          height: em(6px);
          margin-top: em(-3px);
          position: absolute;
          left: em(4px);
          top: em(8px);
          transform: rotate(-45deg);
          width: em(10px);
          z-index: 2;
        }
      }
      input:checked + span {
        &::before {
          background: white;
          border-color: $_marin;
        }
        &::after {
          content: "";
        }
      }
    }

    .text-data-holder {
      position: relative;
    }

    ul.tooltip {
      background: white;
      border: em(2px) solid $_marin;
      border-top: none;
      display: none;
      max-height: em(300px);
      left: 0;
      margin-top: em(-2px);
      overflow: auto;
      position: absolute;
      right: 0;
      top: 100%;
      white-space: normal;
      z-index: 10;

      li {
        &:before {
          content: none;
        }
        color: $_dark-blue-deep;
        cursor: pointer;
        display: block;
        font-weight: normal;
        margin: 0;
        padding: em(5px) em(14px);
        text-decoration: none;
        text-transform: none;
        &:hover, &.active {
          background: $_gray-light;
          cursor: pointer;
        }
      }
    }

  }

}