.burger {
  cursor: pointer;
  position: relative;
  &::before, &__helper, &::after {
    background: $_marin;
    height: em(7px);
    left: 0;
    position: absolute;
    width: 100%;
  }
  &::before {
    content: "";
    top: 0;
    transition: top .14s ease-in .2s, transform .2s ease-out, background-color .34s;
    transform: translate3d(0, 0, 0);
    will-change: top, background-color;
  }
  &__helper {
    top: em(7px+6px);
    transition: opacity .34s, background-color .34s;
    transform: translate3d(0, 0, 0);
    will-change: background-color;
  }
  &::after {
    content: "";
    bottom: 0;
    transition: bottom .14s ease-in .2s, transform .2s ease-out, background-color .34s;
    transform: translate3d(0, 0, 0);
    will-change: bottom, background-color;
  }
  &.open {
    &::before {
      background: $_ultra-marin;
      top: em(13px);
      transform: rotate(45deg);
      transition: top .2s ease-in, transform .14s ease-out .2s, background-color .34s;
    }
    &::after {
      background: $_ultra-marin;
      bottom: em(13px);
      transform: rotate(-45deg);
      transition: bottom .2s ease-in, transform .14s ease-out .2s, background-color .34s;
    }
  }
  &.open &__helper {
    background: $_ultra-marin;
    opacity: 0;
    transition: opacity .1s, background-color .34s;
  }
}