.search {

  input[type=text] {
    @extend .button;
    background: white;
    font-weight: normal;
    height: em(50px);
    line-height: 1.4em;
    margin-bottom: 1.5em;
    text-transform: none;
    width: 100%;
  }

  &__button {
    cursor: pointer;
    display: block;
    height: em(50px);
    line-height: 1.4em;
    margin: auto;
  }

  &__loupe {
    display: inline-block;
    height: 1em;
    @include loupe($_dark);
    margin-right: 1em;
    position: relative;

  }


}