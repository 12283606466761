.project {

  &__item {
    margin-bottom: em(46px);
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__image {
    height: em(160px);
    margin-bottom: em(24px);
    overflow: hidden;
    position: relative;

    img {
      bottom: -100%;
      display: block;
      height: auto;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: -100%;
      width: 100%;
    }
  }

  &__title {
    color: $_dark-blue-deep;
    display: block;
    font-family: $openSans;
    font-size: em(18px);
    line-height: 1.4em;
    margin-bottom: 1em;
  }

  &__desc {
    display: none;
  }

  &__detail {
    background: $_pale;
    border-radius: em(4px);
    color: $_dark-pale;
    display: inline-block;
    font-family: $openSans;
    font-size: em(12px);
    font-weight: bold;
    padding: em(10px) em(15px);
    text-transform: uppercase;
  }

}
