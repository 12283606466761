.breadcrumbs {

  margin-bottom: em(28px);
  //margin-left: em(-$indent);

  &__item {
    color: $_blue;
    font-size: em(14px);
    display: inline-block;
    margin-right: em(5px);
    padding: em(5px) 0;
    vertical-align: middle;
    &::after {
      color: $_gray;
      content: "\2192";
      display: inline-block;
      font-size: em(11px);
      margin-left: em(9px);
      vertical-align: 15%;
    }
    &:last-child {
      color: $_marin;
      &::after {
        content: none;
      }
    }

    &:hover {
      text-decoration: underline;
    }
  }
}