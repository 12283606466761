/*COLORS*/
/*FONT FAMILY*/
body {
  font-family: "Arimo", sans-serif;
  font-size: 15px;
  font-size: 4.6875vw;
  line-height: 1.33; }

.button, .callback input[type=text], .search input[type=text] {
  border-radius: 0.13333em;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  padding: 1em 2em;
  text-align: center;
  text-transform: uppercase;
  transition: background .4s, color .4s, box-shadow .4s;
  will-change: background, color; }
  .button:hover, .callback input:hover[type=text], .search input:hover[type=text] {
    box-shadow: inset 0 0 1.33333em 0.33333em rgba(0, 0, 0, 0.3); }
  .button_yellow {
    background: #fedb77;
    color: #272e35; }
    .button_yellow:hover {
      background: #3fffd0; }
    .button_yellow_1:hover {
      background: #0a88ab;
      color: white; }
  .button_marin {
    background: #0a88ab;
    color: white; }
    .button_marin:hover {
      background: #3fffd0; }
  .button_ultra-marin {
    background: #3fffd0;
    color: #272e35; }
    .button_ultra-marin:hover {
      background: #0a88ab;
      color: white; }
  .button_pale {
    background: #e5eaed;
    color: #526983; }
    .button_pale:hover {
      background: #053f64;
      color: white; }
  .button_inline {
    display: inline-block; }
  .button_flat {
    padding-bottom: .7em;
    padding-top: .7em; }
  .button_full {
    padding-left: 0;
    padding-right: 0; }
  .button_diff {
    font-family: "Open Sans", sans-serif;
    font-size: 0.93333em; }
  .button_diff-1 {
    padding: 1em;
    min-width: 0; }
  .button--wrapper .button, .button--wrapper .callback input[type=text], .callback .button--wrapper input[type=text], .button--wrapper .search input[type=text], .search .button--wrapper input[type=text] {
    margin: 1em 0; }

.banner {
  color: white;
  height: 38.2em;
  overflow: hidden;
  position: relative;
  text-align: center; }
  .banner_link {
    cursor: pointer; }
  .banner .wrapper {
    height: 100%;
    white-space: nowrap; }
    .banner .wrapper::after {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: bottom;
      width: 0; }
  .banner__background {
    display: block;
    height: 100%;
    left: -100%;
    margin: auto;
    position: absolute;
    right: -100%;
    top: 0; }
  .banner__logo {
    display: block;
    height: auto;
    width: 100%; }
  .banner__content {
    box-sizing: border-box;
    position: absolute; }
  .banner__button {
    display: inline-block;
    vertical-align: bottom;
    width: 100%; }
  .banner__hpe {
    font-family: "Open Sans", sans-serif;
    font-size: 1.73333em;
    text-align: left; }
    .banner__hpe_logo {
      width: 10.15385em; }
    .banner__hpe_top {
      margin-top: 1.92308em; }
    .banner__hpe_middle, .banner__hpe_bottom {
      font-size: 2.23077em;
      font-weight: lighter; }
    .banner__hpe_bottom {
      margin-top: -0.43103em; }

.brand {
  font-size: 0.93333em;
  padding: 0 0.66667em; }
  .brand__item {
    margin-bottom: 1.86667em; }
  .brand__link {
    color: #063d5a;
    display: block;
    line-height: 1.2em;
    margin: .5em 0;
    position: relative;
    padding-left: 1.06667em; }
    .brand__link::before {
      background: #1ce7ae;
      content: "";
      height: 0.13333em;
      left: 0;
      display: block;
      position: absolute;
      top: .5em;
      width: 0.66667em; }
    .brand__link_brand {
      margin-bottom: 0.53333em;
      padding: 0; }
      .brand__link_brand::before {
        content: none; }
      .brand__link_brand:hover {
        text-decoration: none; }
    .brand__link_all {
      color: #0a88ab;
      margin: .75em 0; }
    .brand__link:hover {
      text-decoration: underline; }
  .brand__image {
    display: block;
    height: auto;
    max-width: 12.93333em;
    width: auto; }

.breadcrumbs {
  margin-bottom: 1.86667em; }
  .breadcrumbs__item {
    color: #063d5a;
    font-size: 0.93333em;
    display: inline-block;
    margin-right: 0.33333em;
    padding: 0.33333em 0;
    vertical-align: middle; }
    .breadcrumbs__item::after {
      color: #9ba4af;
      content: "\2192";
      display: inline-block;
      font-size: 0.73333em;
      margin-left: 0.6em;
      vertical-align: 15%; }
    .breadcrumbs__item:last-child {
      color: #0a88ab; }
      .breadcrumbs__item:last-child::after {
        content: none; }
    .breadcrumbs__item:hover {
      text-decoration: underline; }

.burger {
  cursor: pointer;
  position: relative; }
  .burger::before, .burger__helper, .burger::after {
    background: #0a88ab;
    height: 0.46667em;
    left: 0;
    position: absolute;
    width: 100%; }
  .burger::before {
    content: "";
    top: 0;
    transition: top .14s ease-in .2s, transform .2s ease-out, background-color .34s;
    transform: translate3d(0, 0, 0);
    will-change: top, background-color; }
  .burger__helper {
    top: 0.86667em;
    transition: opacity .34s, background-color .34s;
    transform: translate3d(0, 0, 0);
    will-change: background-color; }
  .burger::after {
    content: "";
    bottom: 0;
    transition: bottom .14s ease-in .2s, transform .2s ease-out, background-color .34s;
    transform: translate3d(0, 0, 0);
    will-change: bottom, background-color; }
  .burger.open::before {
    background: #3fffd0;
    top: 0.86667em;
    transform: rotate(45deg);
    transition: top .2s ease-in, transform .14s ease-out .2s, background-color .34s; }
  .burger.open::after {
    background: #3fffd0;
    bottom: 0.86667em;
    transform: rotate(-45deg);
    transition: bottom .2s ease-in, transform .14s ease-out .2s, background-color .34s; }
  .burger.open .burger__helper {
    background: #3fffd0;
    opacity: 0;
    transition: opacity .1s, background-color .34s; }

.callback {
  color: white; }
  .callback > span {
    display: block;
    text-align: left; }
  .callback__desc, .callback__phone, .callback__email {
    font-family: "Open Sans", sans-serif;
    font-size: 1.73333em;
    line-height: 1.2em;
    font-weight: lighter;
    margin-bottom: 0.57692em; }
  .callback__phone {
    display: inline-block;
    font-size: 2.13333em;
    margin-bottom: 0.3125em;
    white-space: nowrap; }
  .callback__email {
    display: inline-block;
    margin-bottom: 1.84615em;
    text-decoration: underline; }
    .callback__email:hover {
      text-decoration: none; }
  .callback input[type=text] {
    background: white;
    color: #272e35;
    font-weight: normal;
    margin-bottom: 1.5em;
    padding: 1em;
    text-align: left;
    text-transform: none;
    width: 100%; }
  .callback img {
    cursor: pointer;
    max-width: 100%; }
  .callback__submit {
    cursor: pointer;
    margin: auto; }
  .callback .error {
    color: red;
    margin-bottom: .4em;
    margin-top: -1.7em; }
  .callback__agreement {
    display: inline-block;
    margin-bottom: 1.5em;
    position: relative;
    text-align: left; }
    .callback__agreement .error {
      margin-top: 0;
      text-align: center; }
    .callback__agreement input {
      opacity: 0;
      height: 100%;
      position: absolute;
      width: 100%; }
    .callback__agreement span {
      cursor: text;
      display: block;
      position: relative;
      z-index: 2; }
      .callback__agreement span::before {
        background: #ebeff1;
        border: 0.13333em solid #9ba4af;
        border-radius: 0.2em;
        content: "";
        cursor: pointer;
        display: inline-block;
        height: 1.2em;
        margin-right: 0.53333em;
        vertical-align: middle;
        width: 1.2em; }
      .callback__agreement span::after {
        border-bottom: 0.13333em solid #0a88ab;
        border-left: 0.13333em solid #0a88ab;
        cursor: pointer;
        display: block;
        height: 0.4em;
        margin-top: -0.2em;
        position: absolute;
        left: 0.26667em;
        top: 0.6em;
        transform: rotate(-45deg);
        width: 0.66667em;
        z-index: 2; }
    .callback__agreement input:checked + span::before {
      background: white;
      border-color: #0a88ab; }
    .callback__agreement input:checked + span::after {
      content: ""; }

.catalog__item {
  display: block;
  margin-bottom: 3em; }

.catalog__image {
  display: block;
  height: auto;
  margin: 0 auto 1em;
  max-width: 100%;
  width: auto; }

.catalog__title {
  padding-left: 1em;
  padding-right: 1em;
  transition: background .2s, color .2s; }

.catalog__item:hover .catalog__title {
  background: #3fffd0;
  color: #272e35; }

.char {
  border-collapse: collapse;
  margin: 0 1em;
  min-width: 150%; }
  .char__family, .char__data {
    border-bottom: 0.13333em solid #e5eaed;
    padding: 1em 0 1em 1em;
    vertical-align: top; }
  .char__data_middle {
    vertical-align: middle; }
  .char__data_outer {
    padding-right: 3em; }
  .char__family {
    padding: 1.5em .5em 1.5em 0; }
    .char__family_middle {
      padding: 1em .5em 1em 0; }
    .char__family img {
      width: 100%; }
  .char__table {
    border-collapse: collapse;
    table-layout: fixed;
    min-width: 100%; }
    .char__table td {
      border: none; }
    .char__table tr:nth-child(odd) td {
      background: #e5eaed; }
  .char__property, .char__value {
    padding: .5em 1em;
    vertical-align: top;
    width: 45%; }
  .char__value {
    width: 55%;
    white-space: pre-wrap; }

.gt-mobile, .gt-tablet {
  display: none; }

.lt-tablet, .lt-desktop {
  display: block; }

span.lt-tablet, span.lt-desktop,
br.lt-tablet, br.lt-desktop {
  display: inline; }

span.e-tablet, br.e-tablet, .e-tablet {
  display: none; }

.company__header .company__list-link {
  display: none; }

.company__item {
  display: inline-block;
  margin: 1%;
  vertical-align: middle;
  width: 43%; }

.company__holder {
  display: none; }

.company__image img {
  height: auto;
  max-width: 100%;
  width: auto; }

.company__list-link {
  border-bottom: 0.06667em solid #0a88ab;
  color: #0a88ab;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-size: 0.86667em;
  font-weight: bold;
  line-height: 1.8em;
  margin-top: 2em;
  text-decoration: none;
  text-transform: uppercase;
  transition: border-color .2s, color .2s;
  will-change: border-color, color; }
  .company__list-link:hover {
    border-color: #053f64;
    color: #053f64; }

.config-form {
  color: #041c37;
  margin-bottom: 2em;
  position: relative; }
  .config-form__row {
    margin: 1.66667em 0;
    transition: background-color 1s; }
    .config-form__row.aos-animate {
      transform: none !important; }
  .config-form__header {
    background: #e5eaed;
    color: #053f64;
    font-family: "Open Sans", sans-serif;
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 1.66667em;
    padding: 1.33333em 0 1.33333em 6.66667em;
    position: relative;
    text-transform: uppercase; }
    .config-form__header::after {
      border: 0.53333em solid transparent;
      border-top: 0.53333em solid #e5eaed;
      content: "";
      display: block;
      height: 0;
      left: 1.33333em;
      margin-top: -1px;
      position: absolute;
      top: 100%;
      width: 0; }
    .config-form__header_string {
      padding: 0; }
    .config-form__header_stepless {
      padding-left: 0.86667em; }
  .config-form__title {
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    font-size: 1.06667em;
    font-weight: bold;
    margin: 0 0 .5em;
    text-transform: uppercase; }
  .config-form__item {
    display: block;
    margin-bottom: 0;
    padding-left: 0.86667em;
    text-align: justify; }
    .config-form__item::after {
      content: "";
      display: inline-block;
      width: 100%; }
    .config-form__item > * {
      text-align: left; }
    .config-form__item_left {
      text-align: left; }
    .config-form__item_nowrap {
      white-space: nowrap; }
  .config-form__wrapper_indent {
    padding-left: 0.86667em; }
    .config-form__wrapper_indent .scrollable--parent {
      margin-left: -2em; }
  .config-form__item .config-form__label + .config-form__select {
    display: inline-block; }
  .config-form__number {
    border: 0.13333em solid #053f64;
    border-radius: 0.66667em;
    display: inline-block;
    height: 2.13333em;
    left: 0.86667em;
    line-height: 1.93333em;
    margin-right: .2em;
    margin-top: -1.06667em;
    padding: 0 .5em;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 5.33333em; }
  .config-form__label {
    display: inline-block;
    margin: .5em .5em .5em 0; }
  .config-form__brands {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1em;
    word-spacing: -.3em; }
    .config-form__brands > * {
      display: inline-block;
      margin-right: 1em;
      width: 44.07666%;
      max-width: 44.07666%;
      min-width: 44.07666%;
      word-spacing: 0; }
  .config-form__brand {
    margin-bottom: 1em;
    padding-top: 5.33333em;
    position: relative; }
    .config-form__brand input[type=checkbox] {
      left: 0;
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 10; }
    .config-form__brand span {
      background: white;
      border: 0.26667em solid white;
      border-radius: 0.26667em;
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: border-color .2s;
      width: 100%; }
    .config-form__brand input:checked + span {
      border-color: #3fffd0; }
    .config-form__brand:hover span, .config-form__brand:hover input:checked + span {
      border-color: #95e3f2; }
    .config-form__brand img {
      bottom: -200%;
      display: block;
      height: auto;
      left: -200%;
      margin: auto;
      max-height: 85%;
      max-width: 85%;
      position: absolute;
      right: -200%;
      top: -200%;
      width: auto; }
  .config-form__input {
    background: white;
    border: 0.13333em solid #c2ced5;
    border-radius: 0.13333em;
    color: #041c37;
    display: block;
    width: 100%;
    padding: 0.8em 2em 0.8em 0.93333em;
    position: relative; }
  .config-form__select {
    background: white;
    border: 0.13333em solid #c2ced5;
    border-radius: 0.13333em;
    cursor: pointer;
    min-width: 30%;
    position: relative; }
    .config-form__select__item a, .config-form__select__title {
      color: #041c37;
      cursor: pointer;
      display: block;
      font-weight: normal;
      padding: 0.8em 3em 0.8em 0.93333em;
      text-decoration: none;
      text-transform: none; }
    .config-form__select__item:hover {
      background: #ebeff1;
      cursor: pointer; }
    .config-form__select__list {
      background: white;
      border: 0.13333em solid #c2ced5;
      border-top: none;
      display: none;
      max-height: 20em;
      left: -0.13333em;
      margin-top: -0.13333em;
      overflow: auto;
      position: absolute;
      right: -0.13333em;
      top: 100%;
      white-space: normal;
      z-index: 10; }
    .config-form__select__button {
      border-bottom: 0.2em solid #053f64;
      border-left: none;
      border-right: 0.2em solid #053f64;
      border-top: none;
      display: inline-block;
      height: 0.66667em;
      transform: rotate(45deg);
      width: 0.66667em;
      display: block;
      margin-top: -0.46667em;
      position: absolute;
      right: 1.2em;
      top: 50%; }
    .config-form__select.open {
      z-index: 2; }
    .config-form__select.open .config-form__select__list {
      display: block; }
  .config-form__checkbox {
    display: inline-block;
    margin-bottom: 1.33333em;
    margin-right: 1em;
    min-width: 20%;
    position: relative; }
    .config-form__checkbox input {
      bottom: 0;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0; }
    .config-form__checkbox span {
      display: block;
      position: relative; }
      .config-form__checkbox span::before {
        background: white;
        border: 0.13333em solid #c2ced5;
        content: "";
        display: inline-block;
        height: 1.33333em;
        margin-right: .2em;
        vertical-align: -0.26667em;
        width: 1.33333em; }
      .config-form__checkbox span::after {
        border-bottom: 0.13333em solid #0a88ab;
        border-left: 0.13333em solid #0a88ab;
        display: block;
        height: 0.33333em;
        left: 0.33333em;
        position: absolute;
        top: 0.46667em;
        transform: rotate(-45deg);
        width: 0.66667em; }
    .config-form__checkbox input:checked + span::after {
      content: ""; }
  .config-form__add-link {
    color: #053f64;
    display: inline-block;
    padding-left: 2.66667em;
    position: relative;
    text-decoration: underline; }
    .config-form__add-link:hover {
      text-decoration: none; }
  .config-form__plus {
    display: block;
    left: 0.86667em;
    height: 1.2em;
    position: absolute;
    top: 0;
    width: 1.2em; }
    .config-form__plus::before {
      background: #00b0af;
      content: "";
      display: block;
      height: 20%;
      margin-top: -10%;
      position: absolute;
      top: 50%;
      width: 100%; }
    .config-form__plus::after {
      background: #00b0af;
      content: "";
      display: block;
      height: 100%;
      left: 50%;
      margin-left: -10%;
      position: absolute;
      width: 20%; }
  .config-form__remove-link {
    border: 0.13333em solid #0a88ab;
    border-radius: 50%;
    display: block;
    float: right;
    height: 2em;
    width: 2em; }
    .config-form__remove-link::before {
      background: #0a88ab;
      content: "";
      display: block;
      height: 0.13333em;
      margin: auto;
      position: relative;
      top: 0.8em;
      width: 1em; }
  .config-form__row_main {
    background: #0a88ab;
    border-radius: 0.26667em;
    color: white;
    margin: 0;
    padding: 1.6em 0.86667em 0; }
  .config-form__row_selected {
    background: #053f64; }
  .config-form__row_main .config-form__item {
    color: white;
    padding: 0; }
  .config-form__row_main .config-form__header {
    color: white;
    background: none;
    margin-left: -0.86667em;
    padding: 0 0 0 6.66667em; }
    .config-form__row_main .config-form__header::after {
      content: none; }
  .config-form__row_main .config-form__number {
    border-color: white; }
  .config-form__row_main .config-form__select, .config-form__row_main .config-form__select__list {
    border-color: white; }
  .config-form__row_main .config-form__checkbox span::before {
    border-color: #036a86; }
  .config-form::before {
    background: rgba(255, 255, 255, 0.7);
    content: "";
    height: 100%;
    left: -99999px;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity .2s, left 0s linear .2s;
    width: 100%;
    z-index: 9; }
  .config-form__loader, .config-form__loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em; }
  .config-form__loader {
    font-size: 10px;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(28, 231, 174, 0.2);
    border-right: 1.1em solid rgba(28, 231, 174, 0.2);
    border-bottom: 1.1em solid rgba(28, 231, 174, 0.2);
    border-left: 1.1em solid #1ce7ae;
    animation: load8 1.1s infinite linear;
    left: -9999px;
    margin-left: -5em;
    margin-top: -5em;
    opacity: 0;
    position: fixed;
    transition: opacity .2s, left 0s linear .2s;
    top: 40%;
    z-index: 10; }

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
  .config-form_loading .config-form__loader {
    left: 50%;
    opacity: 1;
    transition: opacity .2s; }
  .config-form_loading::before {
    left: 0;
    opacity: 1;
    transition: opacity .2s; }
  .config-form__attention {
    color: #0a88ab;
    display: block;
    font-size: 0.86667em;
    margin: .5em 0;
    text-align: right;
    width: 100%; }
  .config-form__table {
    border-bottom: 0.13333em solid #0a88ab;
    border-spacing: 0.13333em 0; }
    .config-form__table th, .config-form__table td {
      border-bottom: 0.13333em solid white;
      padding: 1.13333em 0.66667em; }
    .config-form__table th {
      background: #0a88ab;
      color: white;
      font-weight: normal;
      text-align: left; }
    .config-form__table td {
      vertical-align: top; }
      .config-form__table td.config-form__button-holder {
        text-align: center;
        width: 1px; }
    .config-form__table tr:nth-child(even) td {
      background: #e5eaed; }
    .config-form__table tr:last-child td {
      border-bottom: 0; }
  .config-form__group-header {
    color: #0a88ab;
    font-family: "Open Sans", sans-serif;
    font-size: 1.33333em;
    font-weight: normal;
    line-height: 1.2em;
    margin: 1.5em 0 1.1em; }

.configurator {
  color: white;
  position: relative; }
  .configurator__item {
    margin-bottom: 2.66667em;
    position: relative;
    text-align: center;
    z-index: 1; }
  .configurator__image {
    backface-visibility: hidden;
    display: block;
    margin: 0 auto 0.66667em;
    width: 70%; }
  .configurator__title {
    font-size: 1.73333em;
    font-weight: lighter; }
    .configurator__title a {
      border-bottom: 0.06667em solid #3fffd0;
      text-decoration: none; }
  .configurator__list {
    background: #037cb6;
    /* Old browsers */
    background: linear-gradient(to bottom, #037cb6 0%, #0eadb3 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#037cb6', endColorstr='#0eadb3',GradientType=0 );
    /* IE6-9 */
    left: -1em;
    min-height: 100%;
    opacity: 0;
    padding: 1.33333em;
    position: absolute;
    right: -1em;
    text-align: left;
    transition: opacity .4s;
    top: -3em;
    z-index: -1; }
  .configurator__item:last-child .configurator__list {
    bottom: -5.33333em;
    top: auto; }
  .configurator__item:hover {
    z-index: 10; }
  .configurator__item:hover .configurator__list {
    opacity: 1;
    transition: opacity .4s, z-index 0s linear .1s;
    z-index: 10; }
  .configurator__list-title {
    font-size: 1.86667em;
    margin-bottom: 1em; }
  .configurator__link {
    display: block;
    font-size: 1.06667em;
    padding: 0.13333em 0;
    text-decoration: underline;
    transition: color 200ms; }
    .configurator__link:hover {
      color: #95e3f2; }
    .configurator__link_list {
      margin-top: 0.66667em; }

.feature__text {
  color: #041c37; }
  .feature__text p {
    margin: .7em 0; }
  .feature__text a {
    color: #0a88ab; }

.feature__menu {
  cursor: pointer;
  height: 2.94667em;
  margin: 2em 0 0;
  position: relative; }
  .feature__menu::after {
    content: "";
    display: block;
    position: absolute;
    right: 0.86667em;
    top: 1.53333em;
    margin-top: -0.53333em;
    z-index: 10;
    border-bottom: 2px solid #0a88ab;
    border-left: none;
    border-right: 2px solid #0a88ab;
    border-top: none;
    display: inline-block;
    height: 10px;
    transform: rotate(45deg);
    width: 10px; }

.feature__wrapper {
  background: white;
  border: 0.13333em solid #c2ced5;
  left: 0;
  position: relative;
  top: 0;
  z-index: 10; }

.feature__tab {
  color: #0a88ab;
  display: none;
  font-size: 0.86667em;
  font-weight: bold;
  padding: 1em;
  text-transform: uppercase; }
  .feature__tab.active {
    display: block; }
  .feature__tab_container {
    display: none; }

.feature__menu_open .feature__tab {
  display: block; }
  .feature__menu_open .feature__tab:hover, .feature__menu_open .feature__tab.active {
    background: #ebeff1; }

.feature__menu_open .feature__tab_container {
  display: none; }

.feature__content {
  position: relative; }

.feature__content .feature__item {
  left: -9999999px;
  opacity: 0;
  position: absolute;
  top: -9999999px;
  transition: opacity .4s; }

.feature__content .feature__item.visible {
  left: auto;
  opacity: 1;
  position: relative;
  top: auto; }

.filter {
  background: #e5eaed;
  margin: 4em -1em 0;
  padding: 1em 0 0 0.66667em;
  position: relative; }
  .filter__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: stretch;
    margin-top: -2.333em;
    padding-top: 1.6em;
    text-align: justify;
    transition: opacity .6s;
    word-spacing: -.3em; }
    .filter__wrapper::after {
      content: "";
      display: inline-block;
      height: 1.333em;
      width: 100%; }
  .filter__closer, .filter__opener {
    background: #e5eaed;
    color: #0a88ab;
    display: block;
    font-size: 0.86667em;
    height: 3.23077em;
    line-height: 1em;
    padding: 1.15385em;
    position: absolute;
    right: 0;
    top: -3.23077em; }
  .filter__opener {
    display: none; }
  .filter__cross {
    display: inline-block;
    height: 0.76923em;
    position: relative;
    width: 0.76923em; }
    .filter__cross::before, .filter__cross::after {
      background: #0a88ab;
      content: "";
      display: block;
      height: 30%;
      margin-top: -15%;
      position: absolute;
      top: 50%;
      transform: rotate(45deg);
      width: 100%; }
    .filter__cross::after {
      transform: rotate(-45deg); }
  .filter__plus {
    display: inline-block;
    height: 0.76923em;
    position: relative;
    width: 0.76923em; }
    .filter__plus::before {
      background: #0a88ab;
      content: "";
      display: block;
      height: 0.23077em;
      margin-top: -0.11538em;
      position: absolute;
      top: 50%;
      width: 0.76923em; }
    .filter__plus::after {
      background: #0a88ab;
      content: "";
      display: block;
      height: 0.76923em;
      left: 50%;
      margin-left: -0.11538em;
      position: absolute;
      width: 0.23077em; }
  .filter__item {
    display: inline-block;
    flex: auto;
    padding: 0.66667em 0.66667em 0 0;
    position: relative;
    transition: opacity .6s;
    white-space: nowrap;
    word-spacing: 0; }
    .filter__item input[type=checkbox] {
      bottom: 0;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0; }
    .filter__item input[type=text] {
      border: 0.13333em solid #9ba4af;
      border-radius: 0.26667em;
      color: #272e35;
      padding: .5em 1em;
      font-size: 1.2em; }
      .filter__item input[type=text]:focus {
        border-color: #0a88ab; }
  .filter__text {
    display: block;
    position: relative;
    text-align: left;
    user-select: none; }
    .filter__text::before {
      background: #ebeff1;
      border: 0.13333em solid #9ba4af;
      border-radius: 0.2em;
      content: "";
      display: inline-block;
      height: 1.2em;
      margin-right: 0.53333em;
      vertical-align: middle;
      width: 1.2em; }
    .filter__text::after {
      border-bottom: 0.13333em solid #0a88ab;
      border-left: 0.13333em solid #0a88ab;
      display: block;
      height: 0.4em;
      margin-top: -0.2em;
      position: absolute;
      left: 0.26667em;
      top: 50%;
      transform: rotate(-45deg);
      width: 0.66667em;
      z-index: 2; }
  .filter__brand {
    border: 0.13333em solid #c2ced5;
    border-radius: 0.2em;
    color: #041c37;
    display: block;
    font-weight: bold;
    padding: .5em;
    text-align: center;
    transition: background-color .2s, border-color .2s, color .2s;
    user-select: none; }
    .filter__brand:hover {
      border-color: #3fffd0; }
  .filter__item input:checked + .filter__text::before {
    background: white;
    border-color: #0a88ab; }
  .filter__item input:checked + .filter__text::after {
    content: ""; }
  .filter__item input:checked + .filter__brand {
    border-color: #0a88ab;
    color: #0a88ab; }
  .filter.closed {
    margin-bottom: 5.33333em;
    padding: 0; }
  .filter.closed .filter__wrapper {
    left: -99999px;
    opacity: 0;
    position: absolute; }
  .filter.closed .filter__opener {
    display: block; }
  .filter.closed .filter__closer {
    display: none; }

.footer {
  color: #041c37;
  font-size: 0.93333em;
  padding-bottom: 2em;
  text-align: center; }
  .footer__logo {
    display: block;
    margin-bottom: 1.6em;
    width: 9.6em; }
    .footer__logo img {
      display: block;
      height: auto;
      width: 100%; }
  .footer__address {
    display: block;
    margin-bottom: 22px; }
  .footer__phone {
    display: inline-block;
    font-size: 1.4em;
    margin-bottom: 0.93333em; }
  .footer__copyright {
    display: inline-block;
    text-decoration: underline; }
    .footer__copyright:hover {
      text-decoration: none; }
    .footer__copyright_white {
      color: white; }
  .footer__white {
    color: white;
    display: inline-block;
    vertical-align: middle; }
  .footer__social {
    display: inline-block;
    margin-right: 1em;
    vertical-align: middle; }

.gallery-popup {
  display: none;
  font-size: 0;
  line-height: 0; }
  .gallery-popup.popup {
    display: block; }
  .gallery-popup__image {
    bottom: 5%;
    display: none;
    left: 0;
    margin: auto;
    max-height: 90%;
    max-width: 90%;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 5%; }
    .gallery-popup__image.active {
      opacity: 1;
      display: block; }
  .gallery-popup__closer {
    height: 30px;
    position: absolute;
    right: 5%;
    top: 5%;
    width: 30px; }
    .gallery-popup__closer::before, .gallery-popup__closer::after {
      background: white;
      content: "";
      display: block;
      height: 4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      transform: rotate(45deg);
      width: 100%; }
    .gallery-popup__closer::after {
      transform: rotate(-45deg); }

.gallery {
  position: relative; }
  .gallery__view {
    border: 0.13333em solid #e5eaed;
    padding-top: 100%;
    position: relative; }
    .gallery__view > img {
      bottom: -100%;
      height: auto;
      left: -100%;
      margin: auto;
      max-height: 100%;
      max-width: 100%;
      opacity: 0;
      position: absolute;
      right: -100%;
      top: -100%;
      transform: translateZ(0);
      transition: visibility 0s, opacity 0.5s linear;
      visibility: hidden;
      width: auto; }
      .gallery__view > img.active {
        opacity: 1;
        visibility: visible; }
  .gallery__thumb {
    border: 0.13333em solid #e5eaed;
    display: inline-block;
    margin: 0 0.26667em;
    padding-top: 6.66667em;
    position: relative;
    width: 6.66667em; }
    .gallery__thumb:first-child {
      margin-left: 0; }
    .gallery__thumb:last-child {
      margin-right: 0; }
    .gallery__thumb > img {
      bottom: -100%;
      display: block;
      height: auto;
      left: -100%;
      margin: auto;
      max-height: 99%;
      max-width: 99%;
      position: absolute;
      right: -100%;
      top: -100%;
      width: auto; }
    .gallery__thumb.active {
      border-color: #0a88ab; }
  .gallery__scroll {
    text-align: center;
    user-select: none;
    white-space: nowrap;
    word-spacing: -.3em; }
  .gallery__loupe {
    cursor: pointer;
    overflow: visible;
    position: relative;
    height: 2em;
    position: absolute;
    right: 0.86667em;
    top: 0.86667em;
    width: 2em;
    z-index: 10; }
    .gallery__loupe::before {
      border: 0.14667em solid #3fffd0;
      border-radius: 50%;
      content: "";
      display: block;
      left: 50%;
      margin-left: -0.73333em;
      height: 1.46667em;
      position: absolute;
      top: 0;
      width: 1.46667em; }
    .gallery__loupe::after {
      background: #3fffd0;
      border-radius: 0.14667em;
      content: "";
      display: block;
      left: 50%;
      margin-left: 0.41905em;
      position: absolute;
      top: 1.17333em;
      width: 0.73333em;
      height: 0.14667em;
      transform: rotate(45deg);
      transform-origin: 0 0; }
  .gallery__plus {
    display: block;
    height: 0.66667em;
    left: 50%;
    margin-left: -0.33333em;
    position: absolute;
    top: 0.4em;
    width: 0.66667em; }
    .gallery__plus::before {
      background: #3fffd0;
      content: "";
      display: block;
      height: 0.13333em;
      margin-top: -0.06667em;
      position: absolute;
      top: 50%;
      width: 0.66667em; }
    .gallery__plus::after {
      background: #3fffd0;
      content: "";
      display: block;
      height: 0.66667em;
      left: 50%;
      margin-left: -0.06667em;
      position: absolute;
      width: 0.13333em; }

.license__item {
  margin-bottom: 3em;
  padding-top: 0.33333em;
  text-align: center; }

.license__image {
  border: 0.13333em solid #c2ced5;
  cursor: pointer;
  display: block;
  margin: 0 auto 1.8em;
  max-width: 75%; }

.license__title {
  color: #0a88ab;
  cursor: pointer;
  text-decoration: underline; }
  .license__title:hover {
    text-decoration: none; }

.list-manager {
  margin-top: 3.06667em;
  text-align: center; }
  .list-manager .pagination {
    margin-bottom: 2em; }
  .list-manager .page-width {
    display: inline-block; }

.map {
  color: #041c37; }
  .map__content {
    height: 35.66667em; }
  .map__desc {
    margin-bottom: 1em; }
  .map__header {
    font-family: "Open Sans", sans-serif;
    font-size: 1.06667em;
    font-weight: bold;
    position: relative;
    text-transform: uppercase; }
  .map__desc_car .map__header {
    min-height: 3.33333em;
    padding: 0.7em 0 0 7.33333em; }
    .map__desc_car .map__header::before {
      background: url(/i/design/map-car.svg) no-repeat;
      content: "";
      display: block;
      height: 2.86667em;
      left: 0;
      margin-top: -1.4em;
      position: absolute;
      top: 50%;
      width: 6.33333em; }
  .map__desc_traction .map__header {
    min-height: 3.33333em;
    padding: 0.7em 0 0 7.33333em; }
    .map__desc_traction .map__header::before {
      background: url(/i/design/map-traction.svg) no-repeat;
      content: "";
      display: block;
      height: 2.53333em;
      left: 0;
      margin-top: -0.66667em;
      position: absolute;
      top: 50%;
      width: 6em; }
  .map__text {
    margin: 1em 0; }

.model {
  margin-top: 2em; }
  .model__item {
    border: 0.13333em solid #e5eaed;
    margin-top: -0.13333em;
    padding-top: 100%;
    position: relative;
    text-align: center;
    width: 100%; }
  .model__inner {
    bottom: 0;
    left: 0;
    padding: 1.33333em;
    position: absolute;
    right: 0;
    top: 0;
    white-space: nowrap; }
    .model__inner::after {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      width: 0; }
  .model__content {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    width: 100%; }
  .model__title {
    color: #0a88ab;
    display: block;
    text-decoration: underline; }
    .model__title:hover {
      text-decoration: none; }
  .model__image {
    display: block;
    margin: 2em auto 0;
    max-height: 16em;
    max-width: 90%; }

.news {
  margin-bottom: 4.33333em; }
  .news__item {
    margin-bottom: 2em; }
  .news__date {
    background: #e5eaed;
    border-radius: 0.26667em;
    color: #526983;
    display: inline-block;
    font-size: 0.86667em;
    margin-bottom: 1em;
    padding: .6em 1em; }
  .news__title {
    color: #041c37;
    display: block;
    font-size: 1.13333em;
    line-height: 1.3em;
    margin-bottom: .5em;
    text-decoration: underline; }
    .news__title:hover {
      text-decoration: none; }
  .news__desc {
    color: #526983;
    font-size: 0.86667em;
    padding-left: 1.33333em;
    position: relative;
    text-align: justify; }
    .news__desc::before {
      color: #0a88ab;
      content: "\2192";
      left: 0;
      position: absolute; }

.page-width {
  background: #f8f9fc;
  border: 0.13333em solid #c2ced5;
  border-radius: 0.2em;
  display: inline-block;
  padding: 0.66667em 1em;
  position: relative;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap; }
  .page-width--wrapper {
    position: relative;
    z-index: 100; }
  .page-width__label {
    color: #063d5a;
    display: inline-block;
    font-size: 1.06667em;
    vertical-align: middle; }
  .page-width__title {
    color: #041c37;
    font-weight: bold;
    margin-right: 1em; }
  .page-width__item {
    color: #041c37;
    padding: 0.5em 1em; }
    .page-width__item a {
      display: block;
      text-decoration: none; }
    .page-width__item:hover {
      background: #e5eaed; }
  .page-width__button {
    border-bottom: 0.13333em solid #0a88ab;
    border-left: none;
    border-right: 0.13333em solid #0a88ab;
    border-top: none;
    display: inline-block;
    height: 0.66667em;
    transform: rotate(45deg);
    width: 0.66667em;
    transition: border-color .2s;
    vertical-align: .2em; }
  .page-width__list {
    background: #f8f9fc;
    border: 0.13333em solid #c2ced5;
    border-top: none;
    border-radius: 0.2em;
    display: none;
    left: -0.13333em;
    margin-top: -0.13333em;
    padding: 0 0 0.66667em;
    position: absolute;
    right: -0.13333em;
    top: 100%; }
  .page-width.open .page-width__list {
    display: block; }
  .page-width.open .page-width__button {
    border-color: #1ce7ae; }

.pagination {
  text-align: center;
  word-spacing: -.3em; }
  .pagination__item {
    border-top: 0.125em solid #041c37;
    color: #063d5a;
    display: inline-block;
    font-size: 1.06667em;
    height: 2.5em;
    line-height: 2.5em;
    text-align: center;
    transition: color .2s;
    vertical-align: top;
    width: 2.5em; }
    .pagination__item_active {
      border-color: #1ce7ae;
      font-weight: bold; }
    .pagination__item:hover {
      color: #0a88ab; }
  .pagination_width-2 .pagination__item {
    width: 50%; }
  .pagination_width-3 .pagination__item {
    width: 33.33%; }
  .pagination_width-4 .pagination__item {
    width: 25%; }
  .pagination_width-5 .pagination__item {
    width: 20%; }
  .pagination_width-6 .pagination__item {
    width: 16.66%; }
  .pagination_width-7 .pagination__item {
    width: 14.28%; }
  .pagination_width-8 .pagination__item {
    width: 12.5%; }
  .pagination_width-9 .pagination__item {
    width: 11.11%; }
  .pagination_width-10 .pagination__item {
    width: 10%; }

.popup-form {
  background: white;
  color: #053f64;
  display: inline-block;
  font-size: 1.06667em;
  line-height: 1.25em;
  padding: 2em 1em 1em;
  position: relative;
  text-align: justify;
  vertical-align: middle;
  white-space: normal; }
  .popup-form::after {
    content: "";
    display: inline-block;
    width: 100%; }
  .popup-form--wrapper {
    bottom: 0;
    left: 0;
    overflow: scroll;
    padding: 1em;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    white-space: nowrap; }
    .popup-form--wrapper::after {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle; }
  .popup-form__title {
    color: #0a88ab;
    font-family: "Open Sans", sans-serif;
    font-size: 1.125em;
    font-weight: bold;
    margin: 1em 1em 1em 0;
    text-transform: uppercase; }
  .popup-form__row {
    display: inline-block;
    margin-bottom: 1em;
    text-align: left;
    vertical-align: top;
    white-space: normal;
    width: 100%; }
    .popup-form__row_captcha img {
      display: block;
      margin: 0 auto 1em; }
    .popup-form__row_checkbox {
      position: relative; }
      .popup-form__row_checkbox input {
        opacity: 0;
        position: absolute; }
      .popup-form__row_checkbox span {
        cursor: text;
        position: relative;
        z-index: 2; }
        .popup-form__row_checkbox span::before {
          background: #ebeff1;
          border: 0.13333em solid #9ba4af;
          border-radius: 0.2em;
          content: "";
          cursor: pointer;
          display: inline-block;
          height: 1.2em;
          margin-right: 0.53333em;
          vertical-align: middle;
          width: 1.2em; }
        .popup-form__row_checkbox span::after {
          border-bottom: 0.13333em solid #0a88ab;
          border-left: 0.13333em solid #0a88ab;
          cursor: pointer;
          display: block;
          height: 0.4em;
          margin-top: -0.2em;
          position: absolute;
          left: 0.26667em;
          top: 0.6em;
          transform: rotate(-45deg);
          width: 0.66667em;
          z-index: 2; }
      .popup-form__row_checkbox input:checked + span::before {
        background: white;
        border-color: #0a88ab; }
      .popup-form__row_checkbox input:checked + span::after {
        content: ""; }
  .popup-form__name {
    display: block;
    margin-bottom: .3em; }
  .popup-form input, .popup-form textarea {
    border: 0.13333em solid #c2ced5;
    border-radius: 0.13333em;
    display: block;
    padding: 0.66667em;
    transition: border-color .2s;
    width: 100%; }
    .popup-form input:focus, .popup-form textarea:focus {
      border-color: #0a88ab; }
    .popup-form input.popup-form__error, .popup-form textarea.popup-form__error {
      border-color: #E65050; }
  .popup-form textarea {
    height: 10em;
    resize: none; }
  .popup-form .button, .popup-form .callback input[type=text], .callback .popup-form input[type=text], .popup-form .search input[type=text], .search .popup-form input[type=text] {
    padding-left: 0;
    padding-right: 0;
    width: 100%; }
  .popup-form__closer {
    display: block;
    height: 1.33333em;
    position: absolute;
    right: 1em;
    top: 1em;
    width: 1.33333em; }
    .popup-form__closer::before, .popup-form__closer::after {
      background: #053f64;
      content: "";
      display: block;
      height: 30%;
      margin-top: -15%;
      position: absolute;
      top: 50%;
      transform: rotate(45deg);
      width: 100%; }
    .popup-form__closer::after {
      transform: rotate(-45deg); }
    .popup-form__closer::before, .popup-form__closer::after {
      transition: background-color .2s; }
    .popup-form__closer:hover::before, .popup-form__closer:hover::after {
      background: #0a88ab; }
  .popup-form .error {
    color: red; }

[data-popup-opener] {
  cursor: pointer; }

.popup-container {
  background: rgba(5, 63, 100, 0.9);
  bottom: -100%;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: auto;
  transition: opacity 400ms, bottom 0ms linear 400ms, top 0ms linear 400ms;
  z-index: 9999; }
  .popup-container_open {
    bottom: 0;
    height: 100%;
    opacity: 1;
    transition: opacity 400ms, bottom 0ms, top 0ms; }
  .popup-container .popup_open {
    display: block !important; }

.popup {
  display: none !important; }

.product-menu {
  font-size: 0.86667em; }
  .product-menu__head {
    color: #9ba4af;
    font-weight: bold; }
    .product-menu__head::after {
      border-bottom: 0.06667em solid #9ba4af;
      content: "";
      display: block;
      margin: 1.5em .5em 0; }
  .product-menu__header {
    display: inline-block;
    margin: 0 .5em .5em; }
  .product-menu__body {
    padding: 2em .5em 0; }
    .wrapper > .product-menu__body {
      margin-bottom: -2em; }
  .product-menu__list {
    margin-bottom: 2em;
    color: #9ba4af; }
  .product-menu__item {
    padding: 0.33333em 0; }
  .product-menu__title {
    color: white;
    margin-bottom: 1.2em;
    text-transform: uppercase; }
  .product-menu__link:hover {
    text-decoration: underline; }

.project__item {
  margin-bottom: 3.06667em; }
  .project__item:last-child {
    margin-bottom: 0; }

.project__image {
  height: 10.66667em;
  margin-bottom: 1.6em;
  overflow: hidden;
  position: relative; }
  .project__image img {
    bottom: -100%;
    display: block;
    height: auto;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: -100%;
    width: 100%; }

.project__title {
  color: #041c37;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2em;
  line-height: 1.4em;
  margin-bottom: 1em; }

.project__desc {
  display: none; }

.project__detail {
  background: #e5eaed;
  border-radius: 0.26667em;
  color: #526983;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-size: 0.8em;
  font-weight: bold;
  padding: 0.66667em 1em;
  text-transform: uppercase; }

.promo {
  bottom: 21.47210075%;
  display: block;
  left: 0;
  position: fixed; }

.result {
  display: block;
  overflow: hidden;
  position: relative;
  text-align: center; }
  .result__list {
    white-space: nowrap; }
  .result__item {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    width: 100%; }
  .result__header {
    background: #00b0af;
    border-radius: 0.26667em;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    margin-bottom: 1em;
    padding: 1em 0;
    position: relative;
    text-transform: uppercase; }
    .result__header::after {
      border: 0.53333em solid transparent;
      border-top: 0.53333em solid #00b0af;
      content: "";
      display: block;
      height: 0;
      left: 50%;
      margin-left: -0.26667em;
      position: absolute;
      top: 100%;
      width: 0; }
  .result__image {
    margin: 0 auto;
    padding-top: 65%;
    position: relative;
    width: 65%; }
    .result__image img {
      bottom: -100%;
      height: auto;
      left: -100%;
      margin: auto;
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      right: -100%;
      top: -100%;
      width: auto; }
    .result__image_logo {
      margin: 1em auto;
      padding-top: 20%; }
  .result__title {
    color: #0a88ab;
    display: block;
    margin-bottom: 2.4em;
    text-decoration: underline; }
    .result__title:hover {
      text-decoration: none; }
  .result__block {
    margin-bottom: 1.33333em;
    text-align: left;
    white-space: normal; }
    .result__block:last-child {
      margin-bottom: 0; }
    .result__block_manufacturer {
      border: 0.46667em solid #053f64;
      border-radius: 0.26667em;
      height: 100%;
      text-align: center; }
      .result__block_manufacturer::after {
        content: "";
        display: inline-block;
        height: 16.66667em;
        vertical-align: middle; }
    .result__block_center {
      text-align: center; }
  .result__line {
    margin-bottom: 1.33333em;
    text-align: left; }
  .result__middle {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    width: 100%; }
  .result__property {
    color: #00b0af;
    font-size: 0.93333em;
    margin-bottom: .2em;
    padding-left: 2em;
    position: relative; }
    .result__property::before {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      display: block;
      height: 1.4em;
      left: 0;
      position: absolute;
      top: 0;
      width: 1.4em; }
    .result__property_sizetype::before, .result__property_server::before {
      background-image: url(/i/design/result-sizetype.svg); }
    .result__property_processor::before {
      background-image: url(/i/design/result-processor.svg); }
    .result__property_ram::before {
      background-image: url(/i/design/result-ram.svg); }
    .result__property_dc::before, .result__property_optional::before {
      background-image: url(/i/design/result-dc.svg); }
    .result__property_hdd::before {
      background-image: url(/i/design/result-hdd.svg); }
    .result__property_raid::before {
      background-image: url(/i/design/result-raid.svg); }
    .result__property_ps::before {
      background-image: url(/i/design/result-ps.svg); }
    .result__property_optional2::before {
      background-image: url(/i/design/result-optional.svg); }
    .result__property_display::before {
      background-image: url(/i/design/result-display.svg); }
  .result__value {
    color: #053f64;
    padding-left: 2em; }
  .result__arrow {
    background: #00b0af;
    border-radius: 50%;
    display: block;
    height: 2.66667em;
    margin-top: -1.33333em;
    position: absolute;
    top: 10em;
    width: 2.66667em; }
    .result__arrow::before {
      content: "";
      display: block;
      margin-top: 0.86667em; }
    .result__arrow_left {
      left: 0; }
      .result__arrow_left::before {
        margin-left: 0.2em;
        border-bottom: 0.2em solid white;
        border-left: 0.2em solid white;
        border-right: none;
        border-top: none;
        display: inline-block;
        height: 0.8em;
        transform: rotate(45deg);
        width: 0.8em; }
    .result__arrow_right {
      right: 0; }
      .result__arrow_right::before {
        margin-right: 0.2em;
        border-bottom: none;
        border-left: none;
        border-right: 0.2em solid white;
        border-top: 0.2em solid white;
        display: inline-block;
        height: 0.8em;
        transform: rotate(45deg);
        width: 0.8em; }
  .result__send.button, .callback input.result__send[type=text], .search input.result__send[type=text] {
    cursor: pointer;
    display: block;
    margin-top: 2em;
    margin-bottom: 1.2em;
    min-width: 0;
    width: 100%; }
  .result__reset {
    color: #053f64;
    cursor: pointer;
    text-decoration: underline; }
    .result__reset:hover {
      text-decoration: none; }
  .result__content {
    overflow: hidden;
    position: relative; }

.review {
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  word-spacing: -.3em; }
  .review h1 {
    white-space: normal;
    word-spacing: 0; }
  .review__item {
    display: inline-block;
    padding: 0 1em;
    vertical-align: top;
    width: 100%; }
  .review__company {
    margin: 0 auto 1em;
    white-space: normal;
    width: 90%; }
  .review__logo, .review__person {
    display: inline-block;
    padding: 0 .25em;
    vertical-align: middle; }
  .review__logo {
    margin-bottom: .5em;
    text-align: center; }
    .review__logo img {
      height: auto;
      max-height: 100%;
      max-width: 100%;
      width: auto; }
  .review__person {
    text-align: left; }
  .review__name {
    color: #0a88ab;
    font-style: italic;
    margin-bottom: .25em; }
  .review__position {
    color: #053f64;
    font-size: 0.86667em;
    font-style: italic; }
  .review__content {
    text-align: left;
    white-space: normal; }
  .review__text {
    color: #041c37;
    margin-bottom: 1em; }
  .review__button {
    font-size: 0.8em; }
  .review__arrow {
    border: 0.13333em solid #0a88ab;
    border-radius: 50%;
    height: 2.66667em;
    position: absolute;
    text-align: center;
    top: 8em;
    transition: border-color .2s;
    will-change: border-color;
    width: 2.66667em; }
    .review__arrow::after {
      content: "";
      margin-top: 0.8em;
      transition: border-color .2s;
      will-change: border-color; }
    .review__arrow_left {
      left: -1em; }
      .review__arrow_left::after {
        border-bottom: 0.13333em solid #0a88ab;
        border-left: 0.13333em solid #0a88ab;
        border-right: none;
        border-top: none;
        display: inline-block;
        height: 0.8em;
        transform: rotate(45deg);
        width: 0.8em;
        margin-left: 0.66667em; }
    .review__arrow_right {
      right: -1em; }
      .review__arrow_right::after {
        border-bottom: none;
        border-left: none;
        border-right: 0.13333em solid #0a88ab;
        border-top: 0.13333em solid #0a88ab;
        display: inline-block;
        height: 0.8em;
        transform: rotate(45deg);
        width: 0.8em;
        margin-right: 0.66667em; }
    .review__arrow:hover, .review__arrow:hover::after {
      border-color: #053f64; }

.reviews {
  color: #041c37; }
  .reviews__year {
    color: #0a88ab;
    font-family: "Open Sans", sans-serif;
    font-size: 2.66667em;
    font-weight: lighter;
    margin-bottom: 0.5em; }
  .reviews__item {
    border: 0.13333em solid #e5eaed;
    margin-bottom: 1.86667em;
    padding: 1.66667em 1.2em; }
  .reviews__image {
    display: block;
    margin-bottom: 1em;
    max-width: 100%; }
  .reviews__text {
    margin-bottom: 1.2em; }
  .reviews__signature {
    color: #526983;
    font-style: italic; }

.scrollable {
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 20px;
  position: relative;
  top: 20px; }
  .scrollable--parent {
    margin: -20px -1em 0;
    overflow: hidden;
    position: relative; }
    .scrollable--parent_fade-right::after {
      background: linear-gradient(to right, transparent 0%, #ffffff 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='transparent', endColorstr='#ffffff',GradientType=1 );
      /* IE6-9 */
      content: "";
      bottom: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 20%; }

.search-result {
  padding-top: 1em; }
  .search-result__item {
    margin-bottom: 1.33333em; }
  .search-result__image {
    border: 0.13333em solid #ebeff1;
    display: block;
    margin-bottom: 0.66667em;
    overflow: hidden;
    padding-top: 100%;
    position: relative; }
    .search-result__image img {
      bottom: -100%;
      height: auto;
      left: -100%;
      margin: auto;
      max-height: 90%;
      max-width: 90%;
      position: absolute;
      right: -100%;
      top: -100%;
      width: auto; }
  .search-result__title {
    color: #041c37;
    display: block;
    font-size: 1.2em;
    margin-bottom: 1em;
    text-align: center; }
    .search-result__title:hover {
      color: #0a88ab; }
  .search-result__desc {
    margin-bottom: .5em; }
  .search-result__label {
    background: #e5eaed;
    border-radius: 0.13333em;
    color: #526983;
    display: block;
    padding: 1em; }
    .search-result__label::before {
      content: "\2192";
      color: #0a88ab;
      display: inline-block;
      margin-right: 0.66667em; }

.search input[type=text] {
  background: white;
  font-weight: normal;
  height: 3.33333em;
  line-height: 1.4em;
  margin-bottom: 1.5em;
  text-transform: none;
  width: 100%; }

.search__button {
  cursor: pointer;
  display: block;
  height: 3.33333em;
  line-height: 1.4em;
  margin: auto; }

.search__loupe {
  display: inline-block;
  height: 1em;
  overflow: visible;
  position: relative;
  margin-right: 1em;
  position: relative; }
  .search__loupe::before {
    border: 0.1em solid #272e35;
    border-radius: 50%;
    content: "";
    display: block;
    left: 50%;
    margin-left: -0.5em;
    height: 1em;
    position: absolute;
    top: 0;
    width: 1em; }
  .search__loupe::after {
    background: #272e35;
    border-radius: 1.5px;
    content: "";
    display: block;
    left: 50%;
    margin-left: 0.28571em;
    position: absolute;
    top: 0.8em;
    width: 0.5em;
    height: 0.1em;
    transform: rotate(45deg);
    transform-origin: 0 0; }

.service-inner {
  padding-top: 0.13333em; }
  .service-inner__item {
    border: 0.13333em solid #e5eaed;
    padding: 1.33333em 0.86667em 1.8em;
    margin-top: -0.13333em; }
  .service-inner__image {
    display: block;
    margin: 0 auto 2em;
    width: 90%; }
  .service-inner__title {
    color: #0a88ab;
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2em;
    margin-bottom: .2em;
    width: 100%; }
  .service-inner__link {
    color: #0a88ab;
    display: block;
    text-decoration: underline; }
    .service-inner__link:hover {
      text-decoration: none; }
  .service-inner__detail {
    background: #e5eaed;
    border-radius: 0.26667em;
    color: #526983;
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    font-size: 0.8em;
    font-weight: bold;
    margin-bottom: 1em;
    padding: 0.66667em 1em;
    text-transform: uppercase; }
  .service-inner__text {
    margin-bottom: 1em; }

.service__item {
  border: 0.13333em solid #ebeff1;
  margin-top: -0.13333em;
  padding: 0.86667em; }
  .service__item:first-child {
    margin-top: 0; }

.service__image {
  display: block;
  height: auto;
  margin: 1.6em auto;
  width: 55%; }

.service__content::after {
  clear: both;
  content: "";
  display: block; }

.service__header {
  color: #0a88ab;
  font-family: "Open Sans", sans-serif;
  font-size: 1.46667em;
  line-height: 1.3em;
  margin-bottom: .8em; }
  .service__header a {
    text-decoration: none; }
    .service__header a:hover {
      text-decoration: underline; }

.service__list {
  margin-bottom: 1em; }
  .service__list_hidden {
    display: none; }

.service__link {
  color: #041c37;
  display: block;
  font-size: 0.93333em;
  line-height: 1.2em;
  margin: .5em 0;
  padding-left: 1.73333em;
  position: relative; }
  .service__link::before {
    color: #0a88ab;
    content: "\2192";
    left: 0;
    position: absolute; }
  .service__link:hover {
    text-decoration: underline; }

.service__detail {
  display: block;
  float: right;
  font-size: 0.86667em;
  padding: 0.66667em 1em; }

.solution__header {
  color: #0a88ab;
  font-family: "Open Sans", sans-serif;
  font-size: 1.66667em;
  line-height: 1.4em;
  text-transform: uppercase; }
  .solution__header_service {
    font-size: 1.33333em;
    text-transform: none; }
    .solution__header_service:hover {
      text-decoration: underline; }
  .solution__header a {
    text-decoration: none; }
    .solution__header a:hover {
      text-decoration: underline; }

.solution__list {
  color: #041c37;
  font-size: 0.6em;
  line-height: normal;
  text-transform: none;
  margin: 1.33em 0 2.5em; }
  .solution__list li {
    margin: 0.33333em 0;
    padding-left: 1.66667em;
    position: relative; }
    .solution__list li::before {
      background: #3fffd0;
      content: "";
      display: block;
      height: 0.13333em;
      left: 0;
      position: absolute;
      top: .6em;
      width: 0.8em; }
    .solution__list li a {
      text-decoration: none; }
      .solution__list li a:hover {
        text-decoration: underline; }

.solution__item {
  text-align: center;
  margin-bottom: 2em; }

.solution__image {
  display: block;
  margin: 1em auto;
  width: 50%; }

.stock-thin {
  margin-top: 1.8em;
  position: relative; }
  .stock-thin--wrapper {
    bottom: 60px;
    display: none;
    position: absolute;
    right: 13px;
    width: 20.93333em; }
  .stock-thin__holder {
    background: #0a88ab;
    line-height: 12em;
    min-height: 12em; }
    .stock-thin__holder_green {
      background: #00b0af; }
  .stock-thin__content {
    display: inline-block;
    line-height: 1.33em;
    padding: 1.33333em 1.66667em;
    vertical-align: middle;
    white-space: normal; }
  .stock-thin__text {
    color: white;
    margin: 0.33333em 0; }
    .stock-thin__text_large {
      font-size: 1.6em;
      line-height: 1.25em; }
  .stock-thin__wrapper {
    height: 18em;
    overflow: hidden;
    position: relative; }
  .stock-thin__image {
    bottom: -300%;
    left: -300%;
    margin: auto;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    right: -300%;
    top: -300%; }
  .stock-thin__button {
    bottom: 1.66667em;
    left: 1.66667em;
    right: 1.66667em;
    padding: 1em;
    position: absolute; }

.stock {
  margin-top: 1.8em;
  position: relative; }
  .stock__holder {
    background: #0a88ab;
    line-height: 12em;
    min-height: 12em; }
    .stock__holder_green {
      background: #00b0af; }
  .stock__content {
    display: inline-block;
    line-height: 1.33em;
    padding: 1.33333em 1.66667em;
    vertical-align: middle;
    white-space: normal; }
  .stock__text {
    color: white;
    margin: 0.33333em 0; }
    .stock__text_large {
      font-size: 1.6em;
      line-height: 1.25em; }
  .stock__wrapper {
    height: 18em;
    overflow: hidden;
    position: relative; }
  .stock__image {
    bottom: -300%;
    left: -300%;
    margin: auto;
    min-width: 100%;
    position: absolute;
    right: -300%;
    top: -300%; }
  .stock__button {
    bottom: 1.66667em;
    left: 1.66667em;
    right: 1.66667em;
    padding: 1em;
    position: absolute; }

.ticket {
  margin-bottom: 1.5em; }
  .ticket a:not([class]) {
    color: #0a88ab; }
  .ticket__item {
    margin-bottom: 1em; }
  .ticket__info {
    margin-top: 1em; }
  .ticket__row {
    border-top: 0.14286em solid #e5eaed;
    font-size: 0.93333em;
    padding: 0.5em 0 0.64286em; }
    .ticket__row_pre {
      white-space: pre; }
  .ticket__header {
    color: #526983;
    margin-bottom: 0.35714em; }
  .ticket__value {
    color: #041c37; }
  .ticket__button.button, .callback input.ticket__button[type=text], .search input.ticket__button[type=text] {
    padding-left: 1em; }
  .ticket__button:hover .ticket__gear::before {
    opacity: 0; }
  .ticket__button:hover .ticket__gear::after {
    opacity: 1; }
  .ticket__gear {
    display: inline-block;
    height: 2.06667em;
    margin: -1em 0 -0.66667em;
    position: relative;
    width: 2.06667em; }
    .ticket__gear::before, .ticket__gear::after {
      background: url(/i/design/ticket-gear.svg);
      background-size: 100%;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      opacity: 1;
      position: absolute;
      top: 0;
      transition: opacity .4s;
      width: 100%; }
    .ticket__gear::after {
      background: url(/i/design/ticket-gear-hover.svg);
      opacity: 0; }

.wysiwyg {
  color: #041c37; }
  .wysiwyg p:not([class]) {
    margin: 0.66667em 0; }
  .wysiwyg ul li {
    list-style: none;
    margin: 0.66667em 0;
    padding-left: 1.6em;
    position: relative; }
    .wysiwyg ul li::before {
      background: #1ce7ae;
      content: "";
      display: block;
      height: 0.13333em;
      left: 0;
      position: absolute;
      top: .6em;
      width: 0.86667em; }
  .wysiwyg .blue {
    color: #0a88ab; }
  .wysiwyg h2 {
    font-family: "Open Sans", sans-serif;
    font-size: 1.66667em;
    line-height: 1.2em;
    font-weight: lighter;
    margin-bottom: 0.68em; }
  .wysiwyg h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 1.33333em;
    font-weight: normal;
    line-height: 1.2em;
    margin-bottom: 1.1em; }
  .wysiwyg * + h2 {
    margin-top: 1.15385em; }
  .wysiwyg * + h3 {
    margin-top: 1.5em; }
  .wysiwyg img {
    display: block;
    height: auto;
    margin-bottom: 1em;
    max-width: 100%;
    width: 100%; }
  .wysiwyg .button, .wysiwyg .callback input[type=text], .callback .wysiwyg input[type=text], .wysiwyg .search input[type=text], .search .wysiwyg input[type=text] {
    background: #e5eaed;
    color: #526983;
    display: inline-block;
    padding: 0.66667em 1.33333em; }
    .wysiwyg .button:hover, .wysiwyg .callback input:hover[type=text], .callback .wysiwyg input:hover[type=text], .wysiwyg .search input:hover[type=text], .search .wysiwyg input:hover[type=text] {
      background: #053f64;
      color: white; }
  .wysiwyg ol {
    counter-reset: ol1; }
  .wysiwyg ol ol {
    counter-reset: ol2; }
  .wysiwyg ol ol ol {
    counter-reset: ol3; }
  .wysiwyg ol > li {
    counter-increment: ol1;
    list-style: none;
    margin: 0.66667em 0;
    padding-left: 2em;
    position: relative; }
    .wysiwyg ol > li::before {
      content: counter(ol1) ".";
      color: #0a88ab;
      font-weight: bold;
      left: 0;
      position: absolute; }
  .wysiwyg ol ol > li {
    counter-increment: ol2;
    padding-left: 2.8em; }
    .wysiwyg ol ol > li::before {
      content: counter(ol1) "." counter(ol2) "."; }
  .wysiwyg ol ol ol > li {
    counter-increment: ol3;
    padding-left: 3.6em; }
    .wysiwyg ol ol ol > li::before {
      content: counter(ol1) "." counter(ol2) "." counter(ol3) "."; }
  .wysiwyg a:not([class]) {
    color: #0a88ab;
    text-decoration: underline; }
    .wysiwyg a:not([class]):hover {
      text-decoration: none; }
  .wysiwyg table {
    border-bottom: 0.13333em solid #0a88ab;
    border-spacing: 0.13333em 0; }
    .wysiwyg table th, .wysiwyg table td {
      border-bottom: 0.13333em solid white;
      padding: 1.13333em 0.66667em; }
    .wysiwyg table th {
      background: #0a88ab;
      color: white;
      font-weight: normal;
      text-align: left; }
    .wysiwyg table td {
      vertical-align: top; }
    .wysiwyg table tr:nth-child(even) td {
      background: #e5eaed; }
    .wysiwyg table tr:last-child td {
      border-bottom: 0; }
  .wysiwyg form:not([class]) {
    font-size: 1.06667em; }
    .wysiwyg form:not([class]) img {
      display: block;
      max-width: 100%; }
    .wysiwyg form:not([class]) .error {
      color: red;
      font-size: 0.875em; }
    .wysiwyg form:not([class]) label {
      display: block;
      margin-bottom: 1.33333em; }
      .wysiwyg form:not([class]) label > span {
        display: block;
        margin: 0.8em 0; }
    .wysiwyg form:not([class]) input, .wysiwyg form:not([class]) select, .wysiwyg form:not([class]) textarea {
      background: #e5eaed;
      border: 0.13333em solid #c2ced5;
      border-radius: 0.13333em;
      display: block;
      line-height: 1.4em;
      padding: 0.66667em;
      resize: none;
      transition: background-color .2s, border-color .2s;
      width: 100%; }
      .wysiwyg form:not([class]) input:focus, .wysiwyg form:not([class]) select:focus, .wysiwyg form:not([class]) textarea:focus {
        background: white;
        border-color: #0a88ab; }
    .wysiwyg form:not([class]) textarea {
      height: 7.2em; }
    .wysiwyg form:not([class]) *[type="submit"] {
      border-radius: 0.13333em;
      display: block;
      font-family: "Open Sans", sans-serif;
      font-weight: bold;
      line-height: 1.33333em;
      margin-top: 1.75em;
      padding: 1em 0;
      text-align: center;
      text-transform: uppercase;
      transition: background .4s, color .4s;
      width: 100%;
      will-change: background, color;
      background: #0a88ab;
      color: white; }
      .wysiwyg form:not([class]) *[type="submit"]:hover {
        background: #3fffd0;
        color: #272e35; }
    .wysiwyg form:not([class]) .textarea {
      width: 100%; }
    .wysiwyg form:not([class]) .checkbox {
      font-size: 0.86667em;
      line-height: 1.2em;
      position: relative;
      width: 100%; }
      .wysiwyg form:not([class]) .checkbox input {
        opacity: 0;
        position: absolute; }
      .wysiwyg form:not([class]) .checkbox_independent {
        font-size: 1.06667em; }
      .wysiwyg form:not([class]) .checkbox span {
        cursor: text;
        padding-left: 2em;
        position: relative;
        z-index: 2; }
        .wysiwyg form:not([class]) .checkbox span::before {
          background: #ebeff1;
          border: 0.13333em solid #9ba4af;
          border-radius: 0.2em;
          content: "";
          cursor: pointer;
          display: inline-block;
          height: 1.2em;
          left: 0;
          margin-right: 0.53333em;
          position: absolute;
          vertical-align: middle;
          width: 1.2em; }
        .wysiwyg form:not([class]) .checkbox span::after {
          border-bottom: 0.13333em solid #0a88ab;
          border-left: 0.13333em solid #0a88ab;
          cursor: pointer;
          display: block;
          height: 0.4em;
          margin-top: -0.2em;
          position: absolute;
          left: 0.26667em;
          top: 0.53333em;
          transform: rotate(-45deg);
          width: 0.66667em;
          z-index: 2; }
      .wysiwyg form:not([class]) .checkbox input:checked + span::before {
        background: white;
        border-color: #0a88ab; }
      .wysiwyg form:not([class]) .checkbox input:checked + span::after {
        content: ""; }
    .wysiwyg form:not([class]) .text-data-holder {
      position: relative; }
    .wysiwyg form:not([class]) ul.tooltip {
      background: white;
      border: 0.13333em solid #0a88ab;
      border-top: none;
      display: none;
      max-height: 20em;
      left: 0;
      margin-top: -0.13333em;
      overflow: auto;
      position: absolute;
      right: 0;
      top: 100%;
      white-space: normal;
      z-index: 10; }
      .wysiwyg form:not([class]) ul.tooltip li {
        color: #041c37;
        cursor: pointer;
        display: block;
        font-weight: normal;
        margin: 0;
        padding: 0.33333em 0.93333em;
        text-decoration: none;
        text-transform: none; }
        .wysiwyg form:not([class]) ul.tooltip li:before {
          content: none; }
        .wysiwyg form:not([class]) ul.tooltip li:hover, .wysiwyg form:not([class]) ul.tooltip li.active {
          background: #ebeff1;
          cursor: pointer; }
