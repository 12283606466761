.stock {



  margin-top: em(27px);
  position: relative;

  &__holder {
    background: $_marin;
    line-height: em(180px);
    min-height: em(180px);

    &_green {
      background: $_ultra-marin-darker;
    }
  }

  &__content {
    display: inline-block;
    line-height: 1.33em;
    padding: em(20px) em(25px);
    vertical-align: middle;
    white-space: normal;
  }

  &__text {
    color: white;
    margin: em(5px) 0;
    &_large {
      font-size: em(24px);
      line-height: em(30px, 24px);
    }
  }

  &__wrapper {
    height: em(270px);
    overflow: hidden;
    position: relative;
  }

  &__image {
    bottom: -300%;
    left: -300%;
    margin: auto;
    //min-height: 100%;
    min-width: 100%;
    position: absolute;
    right: -300%;
    top: -300%;
  }

  &__button {
    bottom: em(25px);
    left: em(25px);
    right: em(25px);
    padding: 1em;
    position: absolute;

  }



}