[data-popup-opener] {
  cursor:pointer;
}

.popup-container {
  background: rgba($_dark_blue, .9);
  bottom: -100%;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: auto;
  transition: opacity 400ms, bottom 0ms linear 400ms, top 0ms linear 400ms;
  z-index: 9999;
  &_open {
    bottom: 0;
    height: 100%;
    opacity: 1;
    //top: 0;
    transition: opacity 400ms, bottom 0ms, top 0ms;
  }
  .popup_open {
    display: block!important;

  }
}

.popup {
  display: none!important;
}