.pagination {

  text-align: center;
  word-spacing: -.3em;

  &__item {
    border-top: em(2px, 16px) solid $_dark-blue-deep;
    color: $_blue;
    display: inline-block;
    font-size: em(16px);
    height: em(40px, 16px);
    line-height: em(40px, 16px);
    text-align: center;
    transition: color .2s;
    vertical-align: top;
    width: em(40px, 16px);
    &_active {
      border-color: $_ultra-marin-dark;
      font-weight: bold;
    }
    &:hover {
      color: $_marin;
    }
  }



  &_width-2 &__item {
    width: 50%;
  }

  &_width-3 &__item {
    width: 33.33%;
  }

  &_width-4 &__item {
    width: 25%;
  }

  &_width-5 &__item {
    width: 20%;
  }

  &_width-6 &__item {
    width: 16.66%;
  }

  &_width-7 &__item {
    width: 14.28%;
  }

  &_width-8 &__item {
    width: 12.5%;
  }

  &_width-9 &__item {
    width: 11.11%;
  }

  &_width-10 &__item {
    width: 10%;
  }

}