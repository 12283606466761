.list-manager {
  margin-top: em(46px);
  text-align: center;

  & .pagination {
    margin-bottom: em(30px);
  }

  & .page-width {
    display: inline-block;
  }
}