.footer {

  color: $_dark-blue-deep;
  font-size: em(14px);
  padding-bottom: 2em;
  text-align: center;

  &__logo {
    display: block;
    margin-bottom: em(24px);
    width: em(144px);
    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }

  &__address {
    display: block;
    margin-bottom: 22px;
  }

  &__phone {
    display: inline-block;
    font-size: em(21px);
    margin-bottom: em(14px);
  }

  &__copyright {
    display: inline-block;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
    &_white {
      color: white;
    }
  }

  &__white {
    color: white;
    display: inline-block;
    vertical-align: middle;
  }

  &__social {
    display: inline-block;
    margin-right: 1em;
    vertical-align: middle;
  }

}