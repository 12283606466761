.page-width {

  &--wrapper {
    position: relative;
    z-index: 100;
  }

  &__label {
    color: $_blue;
    display: inline-block;
    font-size: em(16px);
    vertical-align: middle;
  }

  background: $_gray-lightest;
  border: em(2px) solid $_gray-middle;
  border-radius: em(3px);
  display: inline-block;
  padding: em(10px) em($indent);
  position: relative;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;



  &__title {
    color: $_dark-blue-deep;
    font-weight: bold;
    margin-right: 1em
  }

  &__item {
    color: $_dark-blue-deep;
    padding: .5em em($indent);
    a {
      display: block;
      text-decoration: none;
    }
    &:hover {
      background: $_pale;
    }
  }

  &__button {
    @include arrow-down($_marin, em(10px), em(2px));
    transition: border-color .2s;
    vertical-align: .2em;
  }

  &__list {
    background: $_gray-lightest;
    border: em(2px) solid $_gray-middle;
    border-top: none;
    border-radius: em(3px);
    display: none;
    left: em(-2px);
    margin-top: em(-2px);
    padding: 0 0 em(10px);
    position: absolute;
    right: em(-2px);
    top: 100%;

  }

  &.open &__list {
    display: block;
  }
  &.open &__button {
    border-color: $_ultra-marin-dark;
  }


}