.banner {

  color: white;
  height: em(573px);
  overflow: hidden;
  position: relative;
  text-align: center;

  &_link {
    cursor: pointer;
  }

  .wrapper {
    height: 100%;
    white-space: nowrap;
    &::after {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: bottom;
      width: 0;
    }
  }

  &__background {

    display: block;
    height: 100%;
    left: -100%;
    margin: auto;
    position: absolute;
    right: -100%;
    top: 0;
  }

  &__logo {
    display: block;
    height: auto;
    width: 100%;
  }

  &__content {
    box-sizing: border-box;
    position: absolute;
  }

  &__button {
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
  }


  &__hpe {
    font-family: $openSans;
    font-size: em(26px);
    text-align: left;
    &_logo {
      width: em(264px, 26px);
    }
    &_top {
      margin-top: em(50px, 26px);
    }
    &_middle, &_bottom {
      font-size: em(58px, 26px);
      font-weight: lighter;
    }
    &_bottom {
      margin-top: em(-25px, 58px);
      //margin-bottom: em(128px, 58px);
    }
  }






}