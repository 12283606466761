.result {

  display: block;
  overflow: hidden;
  position: relative;
  text-align: center;



  &__list {
    white-space: nowrap;
  }

  &__item {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    width: 100%;
  }

  &__header {
    background: $_ultra-marin-darker;
    border-radius: em(4px);
    color: white;
    font-family: $openSans;
    font-weight: bold;
    margin-bottom: 1em;
    padding: 1em 0;
    position: relative;
    text-transform: uppercase;
    &::after {
      border: em(8px) solid transparent;
      border-top: em(8px) solid $_ultra-marin-darker;
      content: "";
      display: block;
      height: 0;
      left: 50%;
      margin-left: em(-4px);
      position: absolute;
      top: 100%;
      width: 0;
    }
  }

  &__image {

    margin: 0 auto;
    padding-top: 65%;
    position: relative;
    width: 65%;

    img {
      bottom: -100%;
      height: auto;
      left: -100%;
      margin: auto;
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      right: -100%;
      top: -100%;
      width: auto;
    }

    &_logo {
      margin: 1em auto;
      padding-top: 20%;

    }
  }

  &__title {
    color: $_marin;
    display: block;
    margin-bottom: em(36px);
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  &__block {
    margin-bottom: em(20px);
    text-align: left;
    white-space: normal;
    &:last-child {
      margin-bottom: 0;
    }
    &_manufacturer {
      border: em(7px) solid $_dark-blue;
      border-radius: em(4px);
      height: 100%;
      text-align: center;
      &::after {
        content: "";
        display: inline-block;
        height: em(250px);
        vertical-align: middle;
      }
    }
    &_center {
      text-align: center;
    }
  }

  &__line {
    margin-bottom: em(20px);
    text-align: left;
  }

  &__middle {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    width: 100%;
  }

  &__property {
    color: $_ultra-marin-darker;
    font-size: em(14px);
    margin-bottom: .2em;
    padding-left: em(30px);
    position: relative;

    &::before {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      display: block;
      height: em(21px);
      left: 0;
      position: absolute;
      top: 0;
      width: em(21px);
    }
    &_sizetype::before, &_server::before {
      background-image: url(/i/design/result-sizetype.svg);
    }
    &_processor::before {
      background-image: url(/i/design/result-processor.svg);
    }
    &_ram::before {
      background-image: url(/i/design/result-ram.svg);
    }
    &_dc::before, &_optional::before {
      background-image: url(/i/design/result-dc.svg);
    }
    &_hdd::before {
      background-image: url(/i/design/result-hdd.svg);
    }
    &_raid::before {
      background-image: url(/i/design/result-raid.svg);
    }
    &_ps::before {
      background-image: url(/i/design/result-ps.svg);
    }
    &_optional2::before {
      background-image: url(/i/design/result-optional.svg);
    }
    &_display::before {
      background-image: url(/i/design/result-display.svg);
    }
  }

  &__value {
    color: $_dark-blue;
    padding-left: em(30px);
  }

  &__arrow {
    background: $_ultra-marin-darker;
    border-radius: 50%;
    display: block;
    height: em(40px);
    margin-top: em(-20px);
    position: absolute;
    top: em(150px);
    width: em(40px);

    &::before {
      content: "";
      display: block;
      margin-top: em(13px);
    }

    &_left {
      left: 0;
      &::before {
        margin-left: em(3px);
        @include arrow-left(white, em(12px), em(3px));
      }
    }

    &_right {
      right: 0;
      &::before {
        margin-right: em(3px);
        @include arrow-right(white, em(12px), em(3px));
      }
    }
  }

  &__send.button {
    cursor: pointer;
    display: block;
    margin-top: 2em;
    margin-bottom: em(18px);
    min-width: 0;
    width: 100%;
  }

  &__reset {
    color: $_dark-blue;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }


  &__content {
    overflow: hidden;
    position: relative;
  }




}