.button {
  border-radius: em(2px);
  display: block;
  font-family: $openSans;
  font-weight: bold;
  padding: 1em 2em;
  text-align: center;
  text-transform: uppercase;
  transition: background .4s, color .4s, box-shadow .4s;
  will-change: background, color;

  &:hover {
    box-shadow: inset 0 0 em(20px) em(5px) rgba(black, .3);
  }

  &_yellow {
    background: $_yellow;
    color: $_dark;
    &:hover {
      background: $_ultra-marin;
    }
    &_1 {
      &:hover {
        background: $_marin;
        color: white;
      }
    }
  }

  &_marin {
    background: $_marin;
    color: white;
    &:hover {
      background: $_ultra-marin;
    }
  }

  &_ultra-marin {
    background: $_ultra-marin;
    color: $_dark;
    &:hover {
      background: $_marin;
      color: white;
    }
  }

  &_pale {
    background: $_pale;
    color: $_dark-pale;
    &:hover {
      background: $_dark-blue;
      color: white;
    }
  }

  &_inline {
    display: inline-block;
  }

  &_flat {
    padding-bottom: .7em;
    padding-top: .7em;
  }

  &_full {
    padding-left: 0;
    padding-right: 0;
  }

  &_diff {
    font-family: $openSans;
    font-size: em(14px);
  }

  &_diff-1 {
    padding: 1em;
    min-width: 0;
  }

  &--wrapper & {
    margin: 1em 0;
  }

}